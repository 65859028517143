import { Injectable, OnDestroy } from '@angular/core';
import { BatchItem, BatchItemUpdate, BatchItemUpdateError } from '@buyiq-app/batch/models/batch-item';
import { BatchService } from '@buyiq-app/batch/services/batch.service';
import { InventoryService } from '@buyiq-app/inventory/services/inventory.service';
import {
    Product,
    ProductParameters,
    ProductSearchApiResponse,
    ProductSearchParameters
} from '@buyiq-app/product/models/product';
import { ErrorType, ShelfTag } from '@buyiq-app/shelf-tags/models/shelf-tag';
import { ShelfTagsService } from '@buyiq-app/shelf-tags/shelf-tags.service';
import { ProductScanConfiguration } from '@buyiq-core/models/scan';
import { User } from '@buyiq-core/models/user';
import { ScanPipelineService } from '@buyiq-core/scan/scan-pipeline.service';
import { BatchStorageService } from '@buyiq-core/storage/batch-storage.service';
import { OrderHistoryStorageService } from '@buyiq-core/storage/order-history-storage.service';
import { ShelfTagStorageService } from '@buyiq-core/storage/shelf-tag-storage.service';
import { UserService } from '@buyiq-core/user/user.service';
import { SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { concatMap, forkJoin, from, Observable, of, reduce, Subject, take } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { OnlineManagerService } from '@buyiq-app/product/services/online-manager.service';
import { InvalidBatchItemService } from '@buyiq-core/app-state/invalid-batch-item.service';
import { ScanService } from '@buyiq-core/scan/scan.service';
import { OrderHistoryService } from '@buyiq-app/product/services/order-history.service';
import { ChainStoreService } from '@buyiq-core/chain-store/chain-store.service';
import { FeatureFlag, LaunchDarklyFeatureFlag } from '@buyiq-core/models/feature-flags';
import { OfflineSyncService } from '@buyiq-app/product/services/offline-sync.service';
import { ProductService } from '@buyiq-app/product/services/product.service';
import { filterSuccessResult, injectQueryClient, takeUntilResultSuccess, toPromise } from '@ngneat/query';
import { SpecialsParameters } from '@cia-front-end-apps/shared/specials';
import { BatchSpecialsService } from '@buyiq-app/product/services/batch-specials.service';
import { StockedNotScannedService } from '@buyiq-app/stocked-not-scanned/stocked-not-scanned.service';
import { SupplierThresholdService } from '@cia-front-end-apps/shared/supplier-threshold/supplier-threshold.service';
import { DetaSynqChainResource } from '@buyiq-app/product/resources/deta-synq-chain.resource';
import { FeatureFlagService } from '@cia-front-end-apps/shared/feature-flag/feature-flag.service';

@Injectable({
    providedIn: 'root'
})
export class AppStateService implements OnDestroy {
    readonly preloadState: Observable<boolean>;
    private preloadStateSubject = new Subject<boolean>();
    private queryClient = injectQueryClient();

    constructor(
        private supplierThresholdService: SupplierThresholdService,
        private shelfTagsStorageService: ShelfTagStorageService,
        private batchStorageService: BatchStorageService,
        private scanPipelineService: ScanPipelineService,
        private inventoryService: InventoryService,
        private shelfTagsService: ShelfTagsService,
        private productService: ProductService,
        private batchService: BatchService,
        private userService: UserService,
        private scanService: ScanService,
        private onlineManagerService: OnlineManagerService,
        private orderHistoryStorage: OrderHistoryStorageService,
        private invalidBatchItemService: InvalidBatchItemService,
        private orderHistoryService: OrderHistoryService,
        private chainStoreService: ChainStoreService,
        private offlineSyncService: OfflineSyncService,
        private batchSpecialsService: BatchSpecialsService,
        private stockedNotScannedService: StockedNotScannedService,
        private detaSynqChainResource: DetaSynqChainResource,
        private featureFlagService: FeatureFlagService
    ) {
        this.preloadState = this.preloadStateSubject.asObservable();
    }

    ngOnDestroy(): void {
        this.preloadStateSubject.next(true);
        this.preloadStateSubject.complete();
    }

    resetPreloadState(): void {
        this.preloadStateSubject.next(false);
    }

    preloadCriticalData(user: User): Observable<void> {
        const isApl = user.features.includes(FeatureFlag.SupportsApl);
        return this.chainStoreService.getVendorRankingsBatch(user.chain.id, user.currentStore.id, user.id, isApl)
            .pipe(
                switchMap(() => this.preloadBatchData(user)),
                map(() => this.markAsPreloadComplete()),
                switchMap(() => this.userService.cachePreviousUserInfo())
            );
    }

    preloadAllOtherData(user: User): Observable<void> {
        const requests = forkJoin({
            inventory: this.preloadInventoryData(user.currentStore.id),
            shelfTags: this.preloadShelfTags(user.currentStore.id),
            orderHistory: this.preloadOrderHistory(user)
        });

        return requests
            .pipe(
                // needs to be run last to avoid keeping any invalid items in the batch
                switchMap(() => this.invalidBatchItemService.removeInvalidBatchItems(user)),
                tap(() => {
                    // data that isn't needed for the first render can be preloaded in tap
                    this.preloadSpecialsData().subscribe();
                    this.preloadStockedProductsVendor(user.currentStore.id).subscribe();
                    this.preloadVendorThresholds(user.currentStore.id);
                    const isAplUser = user.features.includes(FeatureFlag.SupportsApl);
                    if (isAplUser) {
                        this.preloadCustomerShortName(user.chain.id);
                    }
                }),
                map(() => {
                })
            );
    }

    private preloadSpecialsData() {
        return this.batchService.getBatchSpecials().pipe(
            filterSuccessResult(),
            takeUntilResultSuccess()
        );
    }

    private preloadVendorThresholds(chainStoreId: number): void {
        this.batchService.getBatch(chainStoreId)
            .pipe(
                map(batch => {
                    // grab all the unique vendor IDs from the batch
                    const vendorIds = new Set(batch.map(item => {
                        return item?.selectedVendorAttribute?.vendor?.legacyVendorId ?? 0;
                    }));
                    return vendorIds;
                }),
                take(1)
            )
            .subscribe(vendorIds => {
                [...vendorIds]
                    .filter(id => id !== 0)
                    .forEach(vendorId => {
                        this.queryClient.prefetchQuery({
                            queryKey: ['supplier-threshold', chainStoreId, vendorId] as const,
                            queryFn: async () => {
                                return toPromise({
                                    source: this.supplierThresholdService.getSupplierThreshold(chainStoreId, vendorId)
                                });
                            }
                        });
                    });
            });
    }

    private preloadStockedProductsVendor(storeId: number) {
        return this.stockedNotScannedService.getStockedProductVendorsQuery(storeId)
            .result$
            .pipe(
                filterSuccessResult(),
                takeUntilResultSuccess()
            );
    }

    private preloadOrderHistory(user: User): Observable<void> {
        return this.orderHistoryStorage.getAll().pipe(
            switchMap(history => {
                return this.orderHistoryService.getBatchOrderHistory(user.currentStore.id, history).result$;
            }),
            map(() => null),
            catchError(() => of(null)),
            take(1)
        );
    }

    private markAsPreloadComplete(): void {
        this.preloadStateSubject.next(true);
    }

    getBatchItemProductData(batchItemUpdate: BatchItemUpdate, products: Array<Product> = []): BatchItemUpdate {
        const matchingProduct = products.find(product => {
            return product.upc === batchItemUpdate.batchItemSnapshot.upc;
        });
        const errors = [];

        if (!matchingProduct) {
            errors.push(BatchItemUpdateError.ProductNotFound);
        }

        return new BatchItemUpdate({
            ...batchItemUpdate,
            product: matchingProduct,
            errors
        });
    }

    private doBatchesMatch(localBatch: Array<BatchItem>, remoteBatch: Array<BatchItem>): boolean {
        const localCheckSum = this.calcCheckSum(localBatch);
        const remoteCheckSum = this.calcCheckSum(remoteBatch);
        const checkSumsMatch = localCheckSum === remoteCheckSum;
        const localBatchHasProductData = localBatch.some(batchItem => batchItem?.product);
        const localBatchHasVendorAttributes = localBatch.some(batchItem => batchItem?.selectedVendorAttribute);

        return checkSumsMatch && localBatchHasProductData && localBatchHasVendorAttributes;
    }

    private isSameUser(previousUser: User, currentUser: User): boolean {
        const hasUser = previousUser && currentUser;
        return hasUser && previousUser?.id === currentUser?.id && previousUser?.currentStore?.id === currentUser?.currentStore?.id;
    }

    private preloadInventoryData(storeId: number): Observable<void> {
        // sync any pending changes in the local batch, and then preload the inventory data
        return this.offlineSyncService.syncPendingInventoryChanges()
            .pipe(
                tap(() => this.inventoryService.prefetchInventories(storeId)),
                map(() => {
                })
            );
    }

    private preloadShelfTags(storeId: number): Observable<void> {
        return this.shelfTagsStorageService.getAll()
            .pipe(
                switchMap(localShelfTags => {
                    const pendingChanges = localShelfTags.filter(shelfTag => {
                        return shelfTag?.hasPendingChanges;
                    });
                    // if the shelf tag is to be removed and has no ID then it never made it to the server,
                    // and can be removed from offline storage
                    const onlyOfflineChanges = pendingChanges.filter(this.shelfTagsService.filterOnlyOfflineChanges);
                    const shelfTagsToSync = pendingChanges.filter(shelfTag => {
                        return !this.shelfTagsService.filterOnlyOfflineChanges(shelfTag);
                    });
                    return forkJoin({
                        removeOfflineShelfTags: this.shelfTagsService.removeOfflineShelfTags(onlyOfflineChanges),
                        shelfTagsToSync: of(shelfTagsToSync)
                    });
                }),
                switchMap(({ shelfTagsToSync }) => this.syncPendingShelfTagChangesToServer(shelfTagsToSync, storeId)),
                switchMap(() => this.shelfTagsService.getShelfTags(storeId)),
                map(() => {
                })
            );
    }

    private syncPendingBatchChangesToServer(
        localBatch: Array<BatchItem>,
        remoteBatch: Array<BatchItem>,
        storeId: number
    ): Observable<Array<BatchItem>> {
        const localBatchToUpdate = localBatch.filter(batchItem => {
            return batchItem?.hasPendingChanges;
        });
        const upsertRequests = localBatchToUpdate?.map(batchItem => {
            const remoteBatchItem = remoteBatch?.find(remoteItem => {
                return remoteItem?.upc === batchItem?.upc;
            });
            if (!batchItem?.id && remoteBatchItem) {
                batchItem.id = remoteBatchItem.id;
            }
            return of(batchItem).pipe(
                switchMap(item => {
                    const shouldRehydrate = !this.batchService.isValidItem(item);
                    return shouldRehydrate
                        // if for some reason we have a batch item that is missing data, we want to rehydrate it
                        // or else we'll get a 500 error from the server
                        ? this.scanService.rehydrateBatchItem(item).pipe(
                            switchMap(rehydratedItem => {
                                // if the item cannot be found, we want to remove it from the batch
                                if (rehydratedItem.errors.includes(BatchItemUpdateError.ProductNotFound)) {
                                    return this.invalidBatchItemService.removeInvalidBatchItem(item).pipe(
                                        map(() => null)
                                    );
                                }

                                return of(rehydratedItem.updatedBatchItem);
                            })
                        )
                        : of(item);
                }),
                switchMap(item => item ? this.batchService.upsertBatchItem(storeId, item) : of(null))
            );
        });
        return upsertRequests.length > 0 ? forkJoin([...upsertRequests]) : of([]);
    }

    private syncPendingShelfTagChangesToServer(shelfTags: Array<ShelfTag>, storeId: number): Observable<Array<ShelfTag>> {
        const requests = shelfTags.map(shelfTag => {
            let request = this.shelfTagsService.addShelfTag(storeId, shelfTag);
            if (shelfTag.errorType === ErrorType.Delete) {
                request = this.shelfTagsService.removeShelfTag(storeId, shelfTag).pipe(
                    map(() => shelfTag)
                );
            }
            return request;
        });
        return requests.length > 0 ? forkJoin([...requests]) : of([]);
    }

    private preloadBatchData(user: User): Observable<void> {
        let localBatch: Array<BatchItem>;
        return this.batchStorageService.getAll()
            .pipe(
                switchMap(batch => {
                    localBatch = batch;
                    return this.batchService.getBatch(user.currentStore.id);
                }),
                switchMap(batch => this.syncPendingBatchChangesToServer(localBatch, batch, user.currentStore.id)),
                switchMap(() => forkJoin({
                    batch: this.batchService.getBatch(user.currentStore.id),
                    previousUser: this.userService.getPreviousUser()
                })),
                switchMap(({ batch, previousUser }) => {
                    if (batch?.length < 1) {
                        return of([]);
                    }
                    const doBatchesMatch = this.doBatchesMatch(localBatch, batch);
                    const isSameUser = this.isSameUser(previousUser, user);
                    const shouldRehydrate = !doBatchesMatch || !isSameUser;

                    // if we have differences between the local and remote batches, we want to update the local batch
                    return shouldRehydrate
                        ? this.rehydrateBatchItems(batch, user)
                        : of(localBatch);
                }),
                switchMap(batchItems => {
                    return this.batchSpecialsService.getBatchSpecialsQuery(new SpecialsParameters({
                        daysBefore: 0,
                        daysAfter: 60,
                        chainStoreId: user.currentStore.id
                    }), batchItems).result$.pipe(
                        filterSuccessResult(),
                        takeUntilResultSuccess(),
                        map(result => result?.data ?? []),
                        catchError(() => of([])),
                        map(specials => {
                            return batchItems.map(batchItem => {
                                const specialsMatch = specials.filter((special) => {
                                    return (
                                        special.upc === batchItem.upc
                                        && special.vendorId === batchItem.vendorId
                                    );
                                });

                                const updatedBatchItem = new BatchItem({
                                    ...batchItem,
                                    ...this.batchService.mapDealToBatchItem(batchItem, specialsMatch)
                                });
                                return updatedBatchItem;
                            });
                        })
                    );
                }),
                map(batchItems => {
                    let lastScannedItem = null;
                    if (batchItems.length > 0) {
                        lastScannedItem = batchItems.reduce((previous, current) => {
                            return previous.scannedOrder > current.scannedOrder ? previous : current;
                        });
                    }
                    this.batchService.setLastScannedUpc(lastScannedItem?.upc);
                    this.batchService.updateRehydratedItemsAfterInitialLoad(batchItems);
                }));
    }

    private calcCheckSum(batchItems: Array<BatchItem>): string {
        let checkSum = 0.0;
        batchItems.forEach(product => {
            const wsp = product?.wsp ?? 0;
            const quantity = product?.quantity ?? 0;
            checkSum += wsp * quantity;
        });
        return parseFloat((Math.round(checkSum * 100) / 100).toString()).toFixed(2);
    }

    private rehydrateBatchItems(batchItems: Array<BatchItem>, user: User): Observable<Array<BatchItem>> {
        this.orderHistoryStorage.clearStorage();
        const isApl = user.features.includes(FeatureFlag.SupportsApl);
        const searchState = new SearchState({ pageSize: batchItems.length, sortBy: 'productname' });
        return this.productService.search(new ProductSearchParameters({
            searchState,
            productParameters: new ProductParameters(),
            temporaryVendorId: 0,
            batchSearch: true
        }))
            .pipe(
                catchError(() => {
                    return isApl
                        ? this.aplFallbackSearch(batchItems, user)
                        : this.fallbackToGroupSearch(batchItems, user);
                }),
                map(products => {
                    const updateBatchItems = batchItems
                        .map(batchItem => {
                            const initialBatchItemUpdate = new BatchItemUpdate({
                                batchItemSnapshot: batchItem,
                                product: null
                            });
                            return this.getBatchItemProductData(initialBatchItemUpdate, products.items);
                        })
                        .map(batchItemUpdate => {
                            return this.scanPipelineService.mapUpdatesToBatchItem(batchItemUpdate, new ProductScanConfiguration({
                                user,
                                quantityOverride: batchItemUpdate?.batchItemSnapshot?.quantity ?? null,
                                selectedLegacyVendorId: batchItemUpdate?.batchItemSnapshot?.vendorId ?? null,
                                srpOverride: batchItemUpdate?.batchItemSnapshot?.srp ?? null,
                                wspOverride: batchItemUpdate?.batchItemSnapshot?.wsp ?? null,
                                searchText: batchItemUpdate?.batchItemSnapshot?.upc ?? null,
                                sku: batchItemUpdate?.batchItemSnapshot?.sku ?? null
                            }));
                        })
                        .map(batchItemUpdate => batchItemUpdate.updatedBatchItem);

                    return updateBatchItems;
                }),
                catchError(error => {
                    this.onlineManagerService.setOnline(false);
                    return of(batchItems);
                })
            );
    }

    private fallbackToGroupSearch(batchItems: Array<BatchItem>, user: User): Observable<ProductSearchApiResponse> {
        const groupSize = 50;
        const groups = this.chunkArray(batchItems, groupSize);

        return forkJoin(
            groups.map(group => this.searchProductGroup(group, user))
        ).pipe(
            map(responses => {
                const allItems = responses.reduce((acc, response) => {
                    return acc.concat(response.items);
                }, []);
                return new ProductSearchApiResponse({ items: allItems });
            })
        );
    }

    private aplFallbackSearch(batchItems: Array<BatchItem>, user: User): Observable<ProductSearchApiResponse> {
        const groupSize = 1; // APL users always have a group size of 1
        const groups = this.chunkArray(batchItems, groupSize);
        const batchSize = 50; // Process 50 items at a time for APL users
        const batches = this.chunkArray(groups, batchSize);

        return from(batches).pipe(
            concatMap(batch =>
                forkJoin(batch.map(group => this.searchProductGroup(group, user)))
            ),
            reduce((acc, batchResponses) => {
                const items = batchResponses.reduce((batchAcc, response) => {
                    return batchAcc.concat(response.items);
                }, []);
                return acc.concat(items);
            }, [] as any[]),
            map(allItems => new ProductSearchApiResponse({ items: allItems }))
        );
    }

    private searchProductGroup(group: Array<BatchItem>, user: User): Observable<ProductSearchApiResponse> {
        const upcs = group.map(item => item.upc);
        const searchState = new SearchState({
            search: upcs.join(' '), pageSize: group.length, sortBy: 'productname'
        });

        const isApl = user.features.includes(FeatureFlag.SupportsApl);
        const productParameters = isApl
            ? new ProductParameters({
                searchType: 2
            })
            : new ProductParameters();
        return this.productService.search(new ProductSearchParameters({
            searchState,
            productParameters,
            temporaryVendorId: 0,
            batchSearch: false
        })).pipe(
            catchError(error => {
                return of(new ProductSearchApiResponse({ items: [] }));
            })
        );
    }

    private chunkArray<T>(array: T[], size: number): T[][] {
        const chunks = [];
        for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
        }
        return chunks;
    }

    private preloadCustomerShortName(chainId: number): void {
        this.featureFlagService.getFlag(LaunchDarklyFeatureFlag.EnableOrderPlusCustomerShortNameFiltering)
            .pipe(take(1))
            .subscribe(flag => {
                if (flag) {
                    this.queryClient.prefetchQuery({
                        queryKey: ['customer-short-name', chainId] as const,
                        queryFn: async () => {
                            return toPromise({
                                source: this.detaSynqChainResource.get(chainId).pipe(
                                    map(chain => chain?.customerShortName ?? ''),
                                    catchError(() => of(''))
                                )
                            });
                        }
                    });
                }
            });
    }
}

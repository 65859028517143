<mat-toolbar color="accent"
             class="toolbar">
    <mat-toolbar-row class="toolbar-row store-name">
        {{storeName}}
    </mat-toolbar-row>
    <mat-toolbar-row class="toolbar-row">
        {{username}}
    </mat-toolbar-row>
</mat-toolbar>

<mat-nav-list class="nav-list">
    <ng-container *ngFor="let group of navigationGroups">
        <h3 matSubheader>{{group.name}}</h3>

        <mat-list-item *ngFor="let navigationItem of group.items"
                       [routerLink]="navigationItem.path"
                       (keyup.enter)="onKeyPressed(navigationItem)"
                       (click)="onClickNavigationItem(navigationItem)">
            <mat-icon matListItemIcon>{{navigationItem.icon}}</mat-icon>
            <a matListItemTitle>{{navigationItem.displayName}}</a>
        </mat-list-item>
    </ng-container>

    <buyiq-feedback></buyiq-feedback>
</mat-nav-list>

import { Injectable } from '@angular/core';
import { BatchItem } from '@buyiq-app/batch/models/batch-item';
import { Special, SpecialsService } from '@cia-front-end-apps/shared/specials';

@Injectable({
    providedIn: 'root'
})
export class CostService {
    constructor(private specialsService: SpecialsService) {
    }

    getPerUnitCost(batchItem: BatchItem, supportsApl = false): number {
        if (supportsApl) {
            return batchItem.selectedVendorAttribute?.specialsPrice
                ? batchItem.selectedVendorAttribute?.specialsPrice
                : batchItem.wsp ?? 0;
        }
        const hasMetThreshold =
            batchItem.quantity >= batchItem?.deal?.thresholdQty;
        const salePrice =
            hasMetThreshold && batchItem?.deal?.discountPrice < batchItem.wsp
                ? batchItem?.deal?.discountPrice
                : null;
        const wsp = batchItem?.selectedVendorAttribute?.wsp
            ? batchItem?.selectedVendorAttribute?.wsp
            : batchItem.wsp ?? 0;
        const negotiatedPrice = batchItem?.selectedVendorAttribute?.negotiatedPrice
            ? batchItem?.selectedVendorAttribute?.negotiatedPrice
            : null;
        return negotiatedPrice ?? salePrice ?? wsp;
    }

    getSalePrice(batchItem: BatchItem, specials: Array<Special>): number {
        const hasSpecials = specials?.length > 0;
        const hasMetThreshold = hasSpecials
            ? this.hasMetThreshold(batchItem.quantity, specials)
            : false;
        return hasMetThreshold
            ? this.specialsService.getSalePrice(specials)
            : null;
    }

    hasMetThreshold(quantity: number, specials: Array<Special>): boolean {
        const correctDeal = this.specialsService.getBestDeal(specials);
        return quantity >= correctDeal.thresholdQty;
    }
}

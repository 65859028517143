import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { Observable } from 'rxjs';
import { AverageOrderQuantity, AverageOrderQuantityParams } from '@buyiq-app/product/models/order-history';

@Injectable({
    providedIn: 'root'
})
export class AverageOrderHistoryResource implements DataResource<AverageOrderQuantity> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.order}`;
    }

    get(upc: string, quantityParams: AverageOrderQuantityParams): Observable<AverageOrderQuantity> {
        const url = `${this.baseUrl}/products/${upc}/average-history`;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: (quantityParams.chainStoreId || 0).toString(),
                suggestedOrderQuantityDaysBetweenOrders: (quantityParams.suggestedOrderQuantityDaysBetweenOrders || 0).toString(),
                suggestedOrderQuantityPercentageIncrease: (quantityParams.suggestedOrderQuantityPercentageIncrease || 0).toString(),
                vendorId: (quantityParams.vendorId || 0).toString(),
            }
        });
        return this.http.get<AverageOrderQuantity>(url, { params });
    }
}

import { Injectable } from '@angular/core';
import { injectQuery, injectQueryClient } from '@ngneat/query';
import { SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { OrderHistoryResource } from '@buyiq-app/product/resources/order-history.resource';
import {
    AverageOrderQuantityParams,
    ProductOrderHistoryList
} from '@buyiq-app/product/models/order-history';
import { OrderHistoryStorageService } from '@buyiq-core/storage/order-history-storage.service';
import { catchError, map, tap } from 'rxjs/operators';
import { OrderHistoryProductResource } from '@buyiq-app/product/resources/order-history-product.resource';
import { AverageOrderHistoryResource } from '@buyiq-app/product/resources/average-order-history.resource';


@Injectable({
    providedIn: 'root'
})
export class OrderHistoryService {
    private queryClient = injectQueryClient();
    private query = injectQuery();

    constructor(
        private averageOrderHistoryResource: AverageOrderHistoryResource,
        private orderHistoryProductResource: OrderHistoryProductResource,
        private orderHistoryStorageService: OrderHistoryStorageService,
        private orderHistoryResource: OrderHistoryResource,
    ) {
    }

    getOrderHistory(chainStoreId: number, upc: string) {
        const searchState = new SearchState({ pageSize: 6, pageIndex: 0 });
        return this.query({
            queryKey: ['orderHistory', upc, chainStoreId],
            queryFn: () => this.orderHistoryResource.getAll(searchState, chainStoreId, upc).pipe(
                map(res => {
                    const data = res?.items ?? [];
                    return new ProductOrderHistoryList({
                        history: data,
                        upc: upc
                    });
                }),
                tap(history => this.orderHistoryStorageService.setItem(upc, history)),
                catchError(() => this.orderHistoryStorageService.getItem(upc))
            ),
            networkMode: 'offlineFirst',
            initialData: () => {
                const queryData = this.queryClient.getQueryData<Array<ProductOrderHistoryList>>(['orderHistory', chainStoreId]);
                return queryData?.find(histories => histories.upc === upc);
            }
        });
    }

    getBatchOrderHistory(chainStoreId: number, initialData: Array<ProductOrderHistoryList> = []) {
        return this.query({
                queryKey: ['batchOrderHistory', chainStoreId],
                queryFn: () => this.orderHistoryProductResource.getAll(chainStoreId).pipe(
                    tap(histories => {
                        histories.forEach(history => this.orderHistoryStorageService.setItem(history.upc, history));
                    }),
                    catchError(() => this.orderHistoryStorageService.getAll())
                ),
                networkMode: 'offlineFirst',
                initialData
            }
        );
    }

    getCachedOrderHistory(upc: string) {
        return this.query({
            queryKey: ['cachedOrderHistory', upc],
            queryFn: () => this.orderHistoryStorageService.getItem(upc).pipe(
                map(history => history ?? null)
            ),
            networkMode: 'always',
            // stale time 30 seconds
            staleTime: 30000
        });
    }

    getAverageOrderHistory(upc: string, quantityParams: AverageOrderQuantityParams) {
        return this.query({
            queryKey: ['averageOrderHistory', upc, quantityParams],
            queryFn: () => this.averageOrderHistoryResource.get(upc, quantityParams).pipe(
            ),
            networkMode: 'always',
            // stale time 1 hour
            staleTime: 3600000
        });
    }
}

export class AnalyticsEvent {
    category: AnalyticsCategory;
    action: AnalyticsAction;
    trackedValue: string;
    measuredValue?: number;
    customData?: any;

    constructor(options?: Partial<AnalyticsEvent>) {
        Object.assign(this, options);
    }
}

export class BaseAnalyticsService {
    initialize(id: string): void {
    }

    trackPageView(page: string, userId?: number): void {
    }

    trackEvent(event: AnalyticsEvent, customDimensions: Array<AnalyticsDimension>): void {
    }
}

export enum AnalyticsCategory {
    Authentication = 'authentication',
    CameraScanning = 'cameraScanning',
    Receiving = 'receiving',
    OrderSubmission = 'orderSubmission',
    SavedItems = 'myFavoriteItems',
    SupplierManagement = 'supplierManagement',
    ItemQuantityAlert = 'itemQuantityAlert'
}

export enum AnalyticsAction {
    Login = 'login',
    Logout = 'logout',
    OpenCamera = 'openCamera',
    ClickReceivingMenuItem = 'clickReceivingMenuItem',
    UploadReceivedOrder = 'uploadReceivedOrder',
    LineItemMemo = 'lineItemMemo',
    ChangeCondition = 'changeCondition',
    UploadBatch = 'uploadBatch:all',
    UploadBatchDirect = 'uploadBatch:direct',
    ClickSavedItemsMenuItem = 'clickMyFavoritesMenuItem',
    ClickAddToOrder = 'clickAddToOrder',
    ClickReturnToSavedItems = 'clickReturnMyFavorites',
    ClickSavedItemIcon = 'clickFavoriteIcon',
    ClickSupplierDetailsCard = 'clickSupplierDetailsCard',
    DismissHighQuantityNotification = 'dismissHighQuantityNotification',
}

export enum AnalyticsDimensionType {
    UserId,
    BuildVersion,
    ChainStoreId
}

export class AnalyticsDimension {
    type: AnalyticsDimensionType;
    key: string;
    value: string;

    constructor(options?: Partial<AnalyticsDimension>) {
        Object.assign(this, options);
    }
}

export const defaultDimensionValue = 'default';

export const googleAnalyticsDimensionKey = new Map<AnalyticsDimensionType, string>([
    [AnalyticsDimensionType.UserId, 'dimension2'],
    [AnalyticsDimensionType.BuildVersion, 'dimension3'],
    [AnalyticsDimensionType.ChainStoreId, 'dimension4']
]);

export const analyticsDefaultDimensionKey = new Map<AnalyticsDimensionType, string>([
    [AnalyticsDimensionType.UserId, 'userId'],
    [AnalyticsDimensionType.BuildVersion, 'buildVersion'],
    [AnalyticsDimensionType.ChainStoreId, 'storeId']
]);

import { Injectable } from '@angular/core';
import { SearchStateCache } from '@buyiq-app/product/models/search-state-cache';
import { ProductResource } from '@buyiq-app/product/resources/product.resource';
import { AplProductResource } from '@buyiq-app/product/resources/apl-product.resource';
import { UserService } from '@buyiq-core/user/user.service';
import { SortService } from '@cia-front-end-apps/shared/sort';
import { ChainStoreService } from '@buyiq-core/chain-store/chain-store.service';
import {
    ProductSpecialStatus,
    Special,
    SpecialsService
} from '@cia-front-end-apps/shared/specials';
import { SpecialStorageService } from '@buyiq-core/storage/specials-storage.service';
import { DatadogRumService } from '@buyiq-core/analytics/datadog-rum.service';
import {
    Product,
    ProductParameters,
    ProductSearchApiResponse,
    ProductSearchParameters,
    ProductSearchType,
    upcNormalLength,
    VendorAttribute
} from '@buyiq-app/product/models/product';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, switchMap, take, tap } from 'rxjs/operators';
import { FeatureFlag, LaunchDarklyFeatureFlag } from '@buyiq-core/models/feature-flags';
import { VendorRanking, VendorRankingRequest } from '@buyiq-core/models/vendor-ranking';
import { emitValueOnError } from '@cia-front-end-apps/shared/api-interaction';
import moment from 'moment/moment';
import { DetaSynqChainResource } from '@buyiq-app/product/resources/deta-synq-chain.resource';
import { filterSuccessResult, injectQuery, takeUntilResultSuccess } from '@ngneat/query';
import { FeatureFlagService } from '@cia-front-end-apps/shared/feature-flag/feature-flag.service';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    private query = injectQuery();
    public cache: SearchStateCache = null;
    private isApl = false;
    private isRetailerSkuLookup = false;

    constructor(
        private productResource: ProductResource,
        private aplProductResource: AplProductResource,
        private userService: UserService,
        private sortService: SortService,
        private chainStoreService: ChainStoreService,
        private specialsService: SpecialsService,
        private specialStorageService: SpecialStorageService,
        private dataDogRumService: DatadogRumService,
        private featureFlagService: FeatureFlagService,
        private detaSynqChainResource: DetaSynqChainResource
    ) {
    }

    clear() {
        this.specialStorageService.clearStorage();
    }

    search(productSearchParams: ProductSearchParameters): Observable<ProductSearchApiResponse> {
        let parameters: ProductParameters;
        let chainStoreId: number;
        let isAplUser = false;
        return this.userService.getCurrentUser().pipe(
            switchMap((user) => {
                this.isApl = user.features.includes(FeatureFlag.SupportsApl);
                this.isRetailerSkuLookup = user.features.includes(
                    FeatureFlag.RetailerSkuLookup
                );
                const requestConfig = new VendorRankingRequest({
                    chainId: user.chain.id,
                    chainStoreId: user.currentStore.id,
                    userId: user.id,
                    searchText: productSearchParams.searchState.search,
                    isApl: this.isApl,
                    isSku: this.isSkuLookup(
                        productSearchParams.productParameters.searchType,
                        productSearchParams.searchState.search
                    )
                });

                const vendorRankingRequest = emitValueOnError<Array<VendorRanking>>(
                    this.chainStoreService.getVendorRankings(requestConfig),
                    new Array<VendorRanking>()
                );

                return forkJoin({
                    user: of(user),
                    rankings: vendorRankingRequest
                });
            }),
            switchMap(({ user, rankings }) => {
                chainStoreId = user.currentStore.id;
                isAplUser = user.features.includes(
                    FeatureFlag.SupportsApl
                );
                const searchType = this.getSearchType(
                    productSearchParams.productParameters.searchType,
                    productSearchParams.searchState.search,
                    this.isRetailerSkuLookup
                );
                parameters = new ProductParameters({
                    ...productSearchParams.productParameters,
                    distributors: rankings.map(
                        (ranking) => ranking.legacyVendorId
                    ),
                    vendors: searchType === ProductSearchType.Sku || productSearchParams.freeTextSearch
                        ? productSearchParams.productParameters.vendors
                        : [],
                    searchType
                });
                const productsRequest = isAplUser
                    ? this.getCustomerShortName(user.chain.id).pipe(
                        switchMap(customerShortName => this.aplProductResource.getAll(
                            productSearchParams.searchState,
                            parameters,
                            chainStoreId,
                            productSearchParams.batchSearch,
                            customerShortName
                        ))
                    )
                    : this.productResource.getAll(
                        productSearchParams.searchState,
                        parameters,
                        chainStoreId,
                        productSearchParams.batchSearch
                    );

                return forkJoin({
                    products: productsRequest,
                    rankedVendors: of(rankings)
                });
            }),
            map(({ products, rankedVendors }) => {
                const response = products;

                if (products && products.items) {
                    response.items = response.items.map((product) => {
                        product.vendorAttributes = this.sortVendorAttributes(
                            product.vendorAttributes,
                            rankedVendors,
                            productSearchParams.temporaryVendorId
                        );
                        return product;
                    });
                }

                return response;
            }),
            tap(response => {
                const products = response?.items || [];
                if (products.length === 0) {
                    this.dataDogRumService.addAction('Product not found', {
                        isAplUser,
                        parameters,
                        chainStoreId,
                        searchState: productSearchParams.searchState,
                        batchSearch: productSearchParams.batchSearch
                    });
                }
            })
        );
    }

    hasMetVendorOrderRequirements(
        quantity: number,
        vendorAttribute: VendorAttribute
    ): boolean {
        const minimumOrder = vendorAttribute?.minimumOrder ?? 1;
        const hasMetMinimumOrder = quantity >= minimumOrder;

        const orderMultiple = vendorAttribute?.orderMultiple ?? 1;
        const hasMetOrderMultiple = quantity % orderMultiple === 0;

        return hasMetMinimumOrder && hasMetOrderMultiple;
    }

    isOutOfStock(vendorAttribute: VendorAttribute): boolean {
        return (
            !!vendorAttribute?.estimatedRestockDate &&
            moment().isBefore(vendorAttribute?.estimatedRestockDate)
        );
    }

    isOnSale = (specials: Special[]): boolean => {
        const bestSpecial = this.specialsService.sortByPrecedence(specials)[0];
        const specialAlert = this.specialsService.buildProductSpecialAlert(bestSpecial);
        return specialAlert?.status === ProductSpecialStatus.Current ||
            specialAlert?.status === ProductSpecialStatus.Future;
    };

    hasPriceChange(vendorAttribute: VendorAttribute): boolean {
        const thirtyDaysAgo = moment().startOf('day').subtract({ days: 30 });

        return (
            !!vendorAttribute?.datePriceChanged &&
            thirtyDaysAgo.isBefore(vendorAttribute?.datePriceChanged)
        );
    }

    isDiscontinued(vendorAttribute: VendorAttribute): boolean {
        return (
            !!vendorAttribute?.vendorDiscontinueDate &&
            moment().isAfter(vendorAttribute?.vendorDiscontinueDate)
        );
    }

    isAplDiscontinued(product: Product): boolean {
        return !!product?.aplDiscontinued;
    }

    hasExtraLeadTime(vendorAttribute: VendorAttribute): boolean {
        const hasExtraLeadTime = !!vendorAttribute?.extraLeadTime;
        return !!vendorAttribute?.extraLeadTime;
    }

    adjustQuantityToVendorRequirements(
        quantity: number,
        vendorAttribute: VendorAttribute
    ): number {
        const quantityAdjustedToMinimum = Math.max(
            quantity,
            vendorAttribute.minimumOrder ?? 1,
            1
        );
        const orderMultiple = Math.max(vendorAttribute.orderMultiple ?? 1, 1);
        return (
            orderMultiple * Math.ceil(quantityAdjustedToMinimum / orderMultiple)
        );
    }

    /**
     * Vendors are sorted by ranked vendors, then distributors, then directs.
     * Ranked vendors are sorted in ascending order based on their sort index.
     * Within other groups, they are sorted based on the order in which they come from the API.
     */
    sortVendorAttributes(
        vendorAttributes: Array<VendorAttribute>,
        rankedVendors: Array<VendorRanking>,
        temporaryVendorId: number
    ): Array<VendorAttribute> {
        rankedVendors = this.checkTemporaryVendor(
            rankedVendors,
            temporaryVendorId
        );
        return (vendorAttributes ?? []).sort(
            (attribute1: VendorAttribute, attribute2: VendorAttribute) => {
                const ranking1 = this.getVendorAttributeRanking(
                    attribute1,
                    rankedVendors
                );
                const ranking2 = this.getVendorAttributeRanking(
                    attribute2,
                    rankedVendors
                );
                const rankingDifference = ranking1 - ranking2;

                return rankingDifference !== 0
                    ? rankingDifference
                    : this.sortService.compareBooleans(
                        attribute1.distributor,
                        attribute2.distributor,
                        false
                    );
            }
        );
    }

    isSkuLookup(searchType: ProductSearchType, search: string): boolean {
        if (searchType !== ProductSearchType.Sku &&
            searchType !== ProductSearchType.RetailerSku) {
            return false;
        }

        const containsOnlyDigits = /^\d+$/.test(search);
        const isUpcLength = search.length >= upcNormalLength;

        return !(containsOnlyDigits && isUpcLength);
    }

    getCustomerShortName(chainId: number): Observable<string> {
        return this.featureFlagService.getFlag(LaunchDarklyFeatureFlag.EnableOrderPlusCustomerShortNameFiltering).pipe(
            switchMap(flag => {
                if (flag) {
                    return this.getCustomerShortNameQuery(chainId).result$.pipe(
                        filterSuccessResult(),
                        takeUntilResultSuccess(),
                        map(result => result.data)
                    );
                }
                return of('');
            }),
            // needed as the getFlag call doesn't complete and thus any forkJoin is never emitted
            take(1)
        );

    }

    getCustomerShortNameQuery(chainId: number) {
        return this.query({
            queryKey: ['customer-short-name', chainId],
            queryFn: () => this.detaSynqChainResource.get(chainId).pipe(
                map(chain => chain?.customerShortName ?? ''),
                catchError(() => of(''))
            ),
            // 1 hour in ms
            staleTime: 3_600_000
        });
    }


    private getVendorAttributeRanking(
        attribute: VendorAttribute,
        rankedVendors: Array<VendorRanking>
    ): number {
        const regionalDistributor = rankedVendors.find(
            (vendor) => vendor.legacyVendorId === attribute.vendor.legacyId
        );
        return regionalDistributor?.rank ?? Number.MAX_SAFE_INTEGER;
    }

    private checkTemporaryVendor(
        rankedVendors: Array<VendorRanking>,
        temporaryVendorId: number
    ): Array<VendorRanking> {
        if (temporaryVendorId > 0) {
            if (rankedVendors[0]?.legacyVendorId !== temporaryVendorId) {
                //remove the vendor if it exists with another ranking
                rankedVendors = rankedVendors.filter(
                    (o) => o.legacyVendorId !== temporaryVendorId
                );
                const rankedVendor = {
                    id: 0,
                    chainStoreId: rankedVendors[0]?.chainStoreId,
                    userId: rankedVendors[0]?.userId,
                    legacyVendorId: temporaryVendorId,
                    rank: 0,
                    displayName: ''
                };
                //add the temporary vendor to the first element of the array
                rankedVendors.unshift(rankedVendor);
            }
        } else {
            // remove temporary vendor if it exists
            rankedVendors = rankedVendors.filter(
                (o) => o.rank !== 0 && o.id !== 0
            );
        }
        return rankedVendors;
    }

    private getSearchType(
        searchType: ProductSearchType,
        search: string,
        supportsRetailerSkuLookup: boolean
    ): ProductSearchType {
        if (searchType !== ProductSearchType.Sku &&
            searchType !== ProductSearchType.RetailerSku) {
            return searchType;
        }

        const isSkuLookup = this.isSkuLookup(searchType, search);
        let productSearchType = isSkuLookup
            ? ProductSearchType.Sku
            : ProductSearchType.Upc;
        if (isSkuLookup && supportsRetailerSkuLookup) {
            productSearchType = ProductSearchType.RetailerSku;
        }
        return productSearchType;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataResource } from '@hq-custom-types';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettings } from '@cia-front-end-apps/shared/app-settings';
import { SupplierThreshold } from '@cia-front-end-apps/shared/supplier-threshold/supplier-threshold';

@Injectable({
    providedIn: 'root'
})
export class SupplierThresholdsResource implements DataResource<SupplierThreshold> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.vendorData}/stores`;
    }

    get(chainStoreId: number, supplierId: number): Observable<SupplierThreshold> {
        const url = `${this.baseUrl}/${chainStoreId}/thresholds/${supplierId}`;
        return this.http.get<SupplierThreshold>(url);
    }

    create(chainStoreId: number, supplierId: number, model: SupplierThreshold): Observable<SupplierThreshold> {
        const url = `${this.baseUrl}/${chainStoreId}/thresholds/${supplierId}`;
        return this.http.post<SupplierThreshold>(url, model);
    }

    update(chainStoreId: number, supplierId: number, thresholdId: number, model: SupplierThreshold): Observable<SupplierThreshold> {
        const url = `${this.baseUrl}/${chainStoreId}/thresholds/${supplierId}/${thresholdId}`;
        return this.http.put<SupplierThreshold>(url, model);
    }

    remove(chainStoreId: number, supplierId: number, thresholdId: number): Observable<boolean> {
        const url = `${this.baseUrl}/${chainStoreId}/thresholds/${supplierId}/${thresholdId}`;
        return this.http.delete(url)
            .pipe(
                map(successResponse => true),
                catchError(error => of(false))
            );
    }
}

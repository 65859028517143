<button *ngIf="!displayIcon"
        [matMenuTriggerFor]="filterMenu"
        class="filter-menu-button"
        color="accent"
        mat-button
        type="button">
    FILTER
</button>

<button *ngIf="displayIcon"
        [matMenuTriggerFor]="filterMenu"
        color="accent"
        mat-icon-button
        type="button">
    <mat-icon>filter_list</mat-icon>
</button>

<mat-menu #filterMenu="matMenu"
          class="filter-menu"
          xPosition="before"
          yPosition="below">

    <div class="toolbar-header">
        <span class="title">Filter</span>
        <button (click)="onClearFilters()"
                class="clear-button"
                color="accent"
                mat-button>
            CLEAR
        </button>
    </div>

    <buyiq-expansion-panel *ngIf="sortOptions && sortOptions.length > 0"
                           gcPreventMenuClose>
        <buyiq-expansion-panel-title class="section-heading">Sort Options</buyiq-expansion-panel-title>
        <buyiq-sort-menu (changeSortOption)="onChangeSortOption($event)"
                         [clearSort]="clearSort"
                         [defaultSortType]="defaultSortType"
                         [sortOptions]="sortOptions">
        </buyiq-sort-menu>
    </buyiq-expansion-panel>

    <buyiq-expansion-panel *ngIf="filterGroups && filterGroups.length > 0"
                           [expanded]="!!openFilterGroup"
                           gcPreventMenuClose>
        <buyiq-expansion-panel-title class="section-heading">Filters</buyiq-expansion-panel-title>

        <ng-container *ngIf="filterGroups.length > 1 else singleGroup">
            <ng-container *ngFor="let group of filterGroups">
                <buyiq-expansion-panel [expanded]="group.id === openFilterGroup"
                                       [limitHeight]="true"
                                       gcPreventMenuClose>
                    <buyiq-expansion-panel-title
                        class="section-subheading">{{group.displayName}}</buyiq-expansion-panel-title>

                    <mat-selection-list (selectionChange)="onChangeFilter($event, group.id)"
                                        color="accent">
                        <cdk-virtual-scroll-viewport itemSize="48"
                                                     minBufferPx="384"
                                                     maxBufferPx="480"
                                                     [style]="{
                                                         'height': getFilterGroupScrollHeight(filters[group.id])+'px',
                                                     }">
                            <mat-list-option
                                *cdkVirtualFor="let filter of filters[group.id]; trackBy: filterTrackBy"
                                [value]="filter.key"
                                [selected]="filter.isSelected">
                                {{ filter.description }}
                            </mat-list-option>
                        </cdk-virtual-scroll-viewport>
                    </mat-selection-list>
                </buyiq-expansion-panel>
            </ng-container>
        </ng-container>
    </buyiq-expansion-panel>
</mat-menu>

<ng-template #singleGroup>
    <ng-container *ngFor="let group of filterGroups">
        <mat-selection-list (selectionChange)="onChangeFilter($event, group.id)"
                            color="accent">
            <mat-list-option *ngFor="let filter of filters[group.id]; trackBy: filterTrackBy"
                             [selected]="filter.isSelected"
                             [value]="filter.key">
                {{filter.description}}
            </mat-list-option>
        </mat-selection-list>
    </ng-container>
</ng-template>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@buyiq-core/core.module';
import { SharedModule } from '@buyiq-shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideQueryDevTools } from '@ngneat/query-devtools';
import { createIDBPersister, provideQueryClientPersistence } from '@buyiq-core/query/persistance';
import { ANALYTICS_SERVICE } from '@cia-front-end-apps/shared/analytics/analytics';
import { AnalyticsService } from '@buyiq-core/analytics/analytics.service';
import { provideQueryClientOptions } from '@ngneat/query';
import { USER_SERVICE } from '@cia-front-end-apps/shared/saved-items/user-service';
import { UserService } from '@buyiq-core/user/user.service';

const prerequisiteModules = [
    BrowserModule.withServerTransition({ appId: 'serverApp' })
];
const isLocalHost = window.location.hostname.indexOf('localhost') > -1;


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        ...prerequisiteModules,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        RouterModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: ANALYTICS_SERVICE, useClass: AnalyticsService },
        { provide: USER_SERVICE, useClass: UserService },
        isLocalHost ? provideQueryDevTools() : [],
        provideQueryClientOptions({
            defaultOptions: {
                queries: {
                    retryOnMount: false,
                    // we don't want to retry if it's a 401 or 404
                    retry: (failureCount, error) => {
                        const is401 = error['status'] && error['status'] === 401;
                        const is404 = error['status'] && error['status'] === 404;
                        if (is401 || is404) {
                            return false;
                        }
                        return failureCount < 3;
                    }
                }
            }
        }),
        provideQueryClientPersistence(createIDBPersister())
    ]
})
export class AppModule {
}

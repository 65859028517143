import { animate, style, transition, trigger } from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

export enum NotificationType {
    Basic,
    Success,
    Info,
    Warning,
    Error,
    ExtraLeadTime
}

export const notificationTypeToCssClassName = new Map<NotificationType, string>([
    [NotificationType.Basic, 'basic'],
    [NotificationType.Success, 'success'],
    [NotificationType.Info, 'info'],
    [NotificationType.Warning, 'warning'],
    [NotificationType.Error, 'error'],
    [NotificationType.ExtraLeadTime, 'extra-lead-time']
]);

export class Notification {
    id?: number;
    type = NotificationType.Basic;
    text = 'Default Notification';
    duration?: number;
    icon?: string;
    onDismiss?: () => void; // Add this new property

    constructor(options?: Partial<Notification>) {
        Object.assign(this, options);
    }
}

export class NotificationRef {
    data: Notification;
    readonly id: number;

    private readonly state = new Subject<boolean>();

    constructor(options?: Partial<NotificationRef>) {
        Object.assign(this, options);
    }

    onDismiss(): Observable<boolean> {
        return this.state.asObservable()
            .pipe(take(1));
    }

    dismiss(): void {
        this.state.next(false);
        this.state.complete();
    }

    triggerAction(): void {
        this.state.next(true);
        this.state.complete();
    }
}

const enteringStateStyle = style({ opacity: 0, transform: 'translate(0%, -50%)' });
const enteredStateStyle = style({ opacity: 1, transform: 'translate(0%, 0%)' });
const leavingStateStyle = style({ opacity: 0, transform: 'translate(-50%, 0%)' });

const animationTiming = '225ms 0s cubic-bezier(0.4, 0.0, 1, 1)';

// This uses the predefined :enter and :leave states to animate something being added or removed from the DOM
export const notificationAnimation = trigger('slideInOut', [
    transition(':enter', [
        enteringStateStyle,
        animate(animationTiming, enteredStateStyle),
    ]),
    transition(':leave', [
        enteredStateStyle,
        animate(animationTiming, leavingStateStyle)
    ])
]);

<h2 mat-dialog-title
    tabindex="0">
    <mat-icon color="accent">info</mat-icon>
    New Messages
</h2>

<mat-divider></mat-divider>

<mat-dialog-content>
    <mat-nav-list>
        <a *ngFor="let message of messages"
           [routerLink]="[systemMessagesRoute]"
           mat-dialog-close
           mat-list-item>
            <p class="message-title"
               matListItemTitle>
                <strong>{{message.title}}</strong>
            </p>
            <p matListItemLine>{{message.startDateDisplay}}</p>
        </a>
    </mat-nav-list>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions>
    <button class="button"
            color="accent"
            mat-button
            mat-dialog-close>
        Dismiss
    </button>
    <a [routerLink]="[systemMessagesRoute]"
       class="button"
       color="accent"
       mat-dialog-close
       mat-flat-button>
        View All
    </a>
</mat-dialog-actions>

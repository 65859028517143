import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub, HubCapsule } from '@aws-amplify/core';
import { UserService } from '@buyiq-core/user/user.service';
import { BehaviorSubject, from, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';
import { UserCredentials } from '../models/login';
import { routeParts } from '../route-parts';
import { DialogService } from '@buyiq-core/dialog/dialog.service';
import { DialogConfig, DialogType } from '@buyiq-core/models/dialog';
import { SimpleDialogComponent } from '@buyiq-core/dialog/simple-dialog/simple-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    private loggedInState = new BehaviorSubject<boolean>(false);
    private readonly unsubscribe = new Subject<void>();
    private sessionExpiredDialogRef: any = null;
    private isShowingSessionExpiredDialog = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private dialogService: DialogService
    ) {
        Hub.listen('auth', evt => this.onAuthEvent(evt));
        this.isAuthenticated();
    }

    ngOnDestroy(): void {
        if (this.sessionExpiredDialogRef) {
            this.sessionExpiredDialogRef.close();
            this.sessionExpiredDialogRef = null;
            this.isShowingSessionExpiredDialog = false;
        }
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    isAuthenticated(): Observable<boolean> {
        // Had to catch the rejected promise here to prevent a console error about a rejected promise
        // Related to: https://github.com/angular/angular/issues/31680
        const userAuthenticationPromise = Auth.currentUserPoolUser()
            .catch(() => false);

        return from(userAuthenticationPromise)
            .pipe(
                catchError(() => of(false)),
                map(isAuthorized => !!isAuthorized),
                tap(val => this.loggedInState.next(val))
            );
    }

    refreshSession(): Observable<boolean> {
        return from(Auth.currentAuthenticatedUser({ bypassCache: true }))
            .pipe(
                switchMap(() => this.isAuthenticated()),
                catchError(() => of(false))
            );
    }

    redirectToLogin(url: string): Observable<boolean> {
        // Don't redirect if already on login page
        if (this.router.url.startsWith('/' + routeParts.login)) {
            return of(true);
        }

        return from(this.router.navigate([routeParts.login], {
            queryParams: { redir: url }
        })).pipe(
            switchMap(() => this.userService.cachePreviousUserInfo()),
            switchMap(() => this.userService.clearCurrentUserFromStorage()),
            map(() => true),
            tap(() => {
                this.userService.clearCurrentUser();
                this.loggedInState.next(false);
                this.showSessionExpiredDialog();
            })
        );
    }

    private showSessionExpiredDialog(): void {
        if (this.sessionExpiredDialogRef || this.isShowingSessionExpiredDialog) {
            return;
        }

        this.isShowingSessionExpiredDialog = true;

        const dialogConfig = new DialogConfig({
            component: SimpleDialogComponent,
            type: DialogType.Simple,
            data: {
                title: 'Session Expired',
                content: 'Your session has expired. Please log in again.',
                actionButtonText: 'Ok'
            },
            returnRef: true,
            disableClose: true
        });

        this.sessionExpiredDialogRef = this.dialogService.show(dialogConfig);
        this.sessionExpiredDialogRef.pipe(
            take(1),
            takeUntil(this.unsubscribe)
        ).subscribe({
            next: () => {
                this.sessionExpiredDialogRef = null;
                this.isShowingSessionExpiredDialog = false;
            },
            error: () => {
                this.sessionExpiredDialogRef = null;
                this.isShowingSessionExpiredDialog = false;
            }
        });
    }

    authenticate(userCredentials: UserCredentials): Observable<CognitoUser> {
        const username = userCredentials.username.toLowerCase().trim();
        const password = userCredentials.password;
        return from(Auth.signIn(username, password));
    }

    logout(): Observable<any> {
        return from(Auth.signOut()).pipe(
            switchMap(() => this.userService.cachePreviousUserInfo()),
            switchMap(() => this.userService.clearCurrentUserFromStorage()),
            takeUntil(this.unsubscribe)
        );
    }

    getAuthToken(): Observable<string> {
        return from(Auth.currentSession())
            .pipe(
                catchError(() => of(null)),
                map(u => u && u.getIdToken().getJwtToken())
            );
    }

    /* Returns an Observable of the login state - true means the
       user is logged in */
    getLoginState(): Observable<boolean> {
        return this.loggedInState;
    }

    private onAuthEvent(data: HubCapsule) {
        switch (data.payload.event) {
            case 'signIn': {
                this.loggedInState.next(true);
                break;
            }
            case 'signIn_failure':
            case 'signOut': {
                this.loggedInState.next(false);
                break;
            }
            default: {
                break;
            }
        }
    }
}

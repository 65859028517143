import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DetaSynqChain } from '@hq-app/deta-synq/models/deta-synq';
import { DataResource } from '@hq-custom-types';
import { ApiCollectionResponse } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';
import { AppSettings } from '@buyiq-core/models/app-settings';

@Injectable({
    providedIn: 'root'
})
export class DetaSynqChainResource implements DataResource<DetaSynqChain> {
    private baseUrl: string;
    private endpointName = 'detasynq/chains';

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.vendorData}/${this.endpointName}`;
    }

    getAll(): Observable<ApiCollectionResponse<DetaSynqChain>> {
        const url = `${this.baseUrl}`;
        return this.http.get<ApiCollectionResponse<DetaSynqChain>>(url);
    }

    get(chainId: number): Observable<DetaSynqChain> {
        const url = `${this.baseUrl}/${chainId}`;
        return this.http.get<DetaSynqChain>(url);
    }
}

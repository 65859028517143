// The default environment represents local development. The contents of this file will be replaced
// during the build/serve process with the specified environment.

const isLocalHost = window.location.hostname.indexOf('localhost') > -1;
const isDevelopment = isLocalHost
    || window.location.hostname.indexOf('dev') > -1
    || window.location.hostname.endsWith('spsc.io');

const useProductionMode = !isDevelopment;
const urlSubDomain = isDevelopment
    ? '.dev'
    : '';
const baseUrl = `${window.location.protocol}://${window.location.hostname}:${window.location.port}`;
const cognitoClientId = isDevelopment
    ? '1mh9eiv2dn67vr5ugb651etn0h'
    : '4f6ln05ghnf68ls93vu8eqsuee';
const identityPoolId = isDevelopment
    ? 'us-east-1:766ed4b5-86b6-4237-80e7-95b2acda9c09'
    : 'us-east-1:370732b9-d5c0-40fe-abd5-032fbf47b88b';
const userPoolId = isDevelopment
    ? 'us-east-1_ylvbLKLiG'
    : 'us-east-1_OlX3FFtal';
const launchDarklyClientId = isDevelopment
    ? '61df0e8e10ca3613630c1dc6'
    : '61df0e8e10ca3613630c1dc7';
const ga4MeasurementId = isDevelopment
    ? 'G-XWETGE25EX'
    : 'G-9HM2QLF415';

export const environment = {
    useProductionMode,
    urlSubDomain,
    baseUrl,
    googleAnalyticsId: 'UA-101872179-5',
    ga4MeasurementId,
    buildVersion: '3.510',
    cognitoClientId,
    region: 'us-east-1',
    identityPoolId,
    userPoolId,
    googleAutocompleteKey: 'AIzaSyCsTgjvyTiRcWIbZSXkaolwBOVRjod74Mo',
    googleRecaptchaSiteKey: '6LdbeKEcAAAAAAD1X3nksoIYTkn4lr37zStZBJag',
    launchDarklyClientId,
    dataDog: {
        applicationId: '5c51b239-fd1f-43ec-8081-5ea09b714506',
        clientToken: 'pub2b13181647a97c5fac43b9b762973052',
        site: 'datadoghq.com'
    }
};

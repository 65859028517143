import { inject, Injectable } from '@angular/core';
import { SupplierThresholdsResource } from './supplier-thresholds.resource';
import { SupplierThreshold } from '@cia-front-end-apps/shared/supplier-threshold/supplier-threshold';
import { Observable } from 'rxjs';
import { injectQuery } from '@ngneat/query';

@Injectable({
    providedIn: 'root'
})
export class SupplierThresholdService {
    private supplierThresholdsResource = inject(SupplierThresholdsResource);
    private query = injectQuery();

    getSupplierThreshold(chainStoreId: number, supplierId: number): Observable<SupplierThreshold> {
        return this.supplierThresholdsResource.get(chainStoreId, supplierId);
    }

    createSupplierThreshold(chainStoreId: number, supplierId: number, model: SupplierThreshold): Observable<SupplierThreshold> {
        return this.supplierThresholdsResource.create(chainStoreId, supplierId, model);
    }

    updateSupplierThreshold(chainStoreId: number, supplierId: number, thresholdId: number, model: SupplierThreshold): Observable<SupplierThreshold> {
        model.id = thresholdId;
        return this.supplierThresholdsResource.update(chainStoreId, supplierId, thresholdId, model);
    }

    deleteSupplierThreshold(chainStoreId: number, supplierId: number, thresholdId: number): Observable<boolean> {
        return this.supplierThresholdsResource.remove(chainStoreId, supplierId, thresholdId);
    }

    getSupplierThresholdQuery(chainStoreId: number, supplierId: number) {
        return this.query({
            queryKey: ['supplier-threshold', chainStoreId, supplierId] as const,
            queryFn: () => this.getSupplierThreshold(chainStoreId, supplierId),
            // 10 minutes
            staleTime: 600000,
            refetchOnWindowFocus: 'always',
        });
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import {
    BatchUploadEvent,
    UploadErrorType
} from '@buyiq-app/batch/models/batch-upload-event';
import { Product, VendorAttribute } from '@buyiq-app/product/models/product';
import { Special } from '@buyiq-app/product/models/special';
import { CostService } from '@buyiq-core/cost/cost.service';
import {
    ApiErrorCode,
    ApiErrorResponse,
    retryWithDelay
} from '@buyiq-core/models/error-handler';
import { FeatureFlag } from '@buyiq-core/models/feature-flags';
import { BatchStorageService } from '@buyiq-core/storage/batch-storage.service';
import { ToolbarService } from '@buyiq-core/toolbar/toolbar.service';
import { UserService } from '@buyiq-core/user/user.service';
import {
    BatchSummary,
    BatchSummaryType
} from '@buyiq-shared/models/batch-summary';
import { forkJoin, Observable, of, Subject, switchMap, take, throwError } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import {
    BatchItem,
    BatchItemUpdate,
    BatchItemUpdateError
} from '../models/batch-item';
import { OrderAccount } from '../models/order-account';
import { AccountsResource } from '../resources/accounts.resource';
import { BatchResource } from '../resources/batch.resource';
import { OnlineManagerService } from '@buyiq-app/product/services/online-manager.service';
import { DatadogRumService } from '@buyiq-core/analytics/datadog-rum.service';
import { ProductService } from '@buyiq-app/product/services/product.service';
import { BatchSpecialsService } from '@buyiq-app/product/services/batch-specials.service';
import { SpecialsParameters, SpecialsService } from '@cia-front-end-apps/shared/specials';
import { VendorDiscount } from '@buyiq-core/models/vendor-discount';
import { VendorDiscountService } from '@buyiq-app/product/services/vendor-discount.service';

@Injectable({
    providedIn: 'root'
})
export class BatchService implements OnDestroy {
    private lastScannedUpc = '';
    private lastScannedOrder: number;
    private hasLoadedBatch = false;
    private batch = new Array<BatchItem>();
    private pendingDeletions = new Array<BatchItem>();
    private unsubscribe = new Subject<void>();

    constructor(
        private userService: UserService,
        private costService: CostService,
        private batchResource: BatchResource,
        private productService: ProductService,
        private toolbarService: ToolbarService,
        private accountsResource: AccountsResource,
        private batchStorageService: BatchStorageService,
        private onlineManagerService: OnlineManagerService,
        private batchSpecialsService: BatchSpecialsService,
        private specialsService: SpecialsService,
        private datadogRumService: DatadogRumService,
        private vendorDiscountService: VendorDiscountService
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getBatch(chainStoreId: number): Observable<Array<BatchItem>> {
        let request = of(this.batch);

        if (!this.hasLoadedBatch) {
            request = this.batchResource.getAll(chainStoreId).pipe(
                tap((batch) => {
                    this.batch = batch ?? [];
                    this.hasLoadedBatch = true;
                    this.updateBatchSummary();
                    this.hydrateStorage(this.batch);
                }),
                catchError(() => {
                    this.onlineManagerService.setOnline(false);

                    return this.batchStorageService.getAll().pipe(
                        tap((batch) => {
                            this.batch = (batch ?? []).filter((batchItem) => {
                                return batchItem.quantity > 0;
                            });
                            this.pendingDeletions = (batch ?? []).filter(
                                (batchItem) => {
                                    return batchItem.quantity < 1;
                                }
                            );
                            this.updateBatchSummary();
                        })
                    );
                })
            );
        }

        return request;
    }

    getBatchItem(
        search: string,
        searchBy: 'upc' | 'sku' | 'retailerSKU' = 'upc'
    ): BatchItem {
        return this.batch.find((item) => item[searchBy] === search);
    }

    upsertBatchItem(
        chainStoreId: number,
        batchItem: BatchItem
    ): Observable<BatchItemUpdate> {
        return this.upsert(chainStoreId, batchItem).pipe(
            tap(() => this.updateBatchSummary())
        );
    }

    mapScannedOrderToBatchItem(batchItem: BatchItem): BatchItem {
        if (!!this.lastScannedOrder) {
            this.lastScannedOrder = this.lastScannedOrder + 1;
        } else {
            if (this.batch.length > 0) {
                const lastScannedItem = this.batch.reduce(
                    (previous, current) => {
                        return previous.scannedOrder > current.scannedOrder
                            ? previous
                            : current;
                    }
                );
                this.lastScannedOrder = lastScannedItem.scannedOrder + 1;
            } else {
                this.lastScannedOrder = 1;
            }
        }

        return new BatchItem({
            ...batchItem,
            scannedOrder: this.lastScannedOrder
        });
    }

    updateSelectedVendor(
        chainStoreId: number,
        batchItem: BatchItem,
        selectedVendorAttribute: VendorAttribute
    ): Observable<BatchItemUpdate> {
        const originalBatchItem = this.getBatchItem(batchItem.upc);
        const hasValidQuantity = this.productService.hasMetVendorOrderRequirements(
            batchItem.quantity,
            selectedVendorAttribute
        );

        const updatedBatchItem = new BatchItem({
            ...originalBatchItem,
            ...batchItem,
            ...this.mapVendorAttributeToBatchItem(selectedVendorAttribute),
            selectedVendorAttribute: selectedVendorAttribute,
            hasValidQuantity: hasValidQuantity
        });

        return this.upsert(chainStoreId, updatedBatchItem).pipe(
            tap(() => this.updateBatchSummary())
        );
    }

    updateQuantity(
        chainStoreId: number,
        batchItem: BatchItem,
        newQuantity: number
    ): Observable<BatchItemUpdate> {
        const originalBatchItem = this.getBatchItem(batchItem.upc);
        const hasValidQuantity =
            this.productService.hasMetVendorOrderRequirements(
                newQuantity,
                batchItem.selectedVendorAttribute
            );
        const hasSalePrice = newQuantity > 0 && newQuantity >= batchItem?.deal?.thresholdQty;

        const updatedBatchItem = new BatchItem({
            ...originalBatchItem,
            ...batchItem,
            quantity: newQuantity,
            hasValidQuantity: hasValidQuantity,
            salePrice: hasSalePrice ? batchItem?.deal?.discountPrice : null
        });

        return this.upsert(chainStoreId, updatedBatchItem).pipe(
            tap(() => this.updateBatchSummary()),
            map((batchItemUpdate) => {
                return new BatchItemUpdate({
                    ...batchItemUpdate,
                    updatedBatchItem:
                        newQuantity < 1
                            ? null
                            : batchItemUpdate.updatedBatchItem
                });
            })
        );
    }

    updatePagePartition(
        chainStoreId: number,
        batchItem: BatchItem,
        newPagePartition: number
    ): Observable<BatchItemUpdate> {
        const originalBatchItem = this.getBatchItem(batchItem.upc);
        const updatedBatchItem = new BatchItem({
            ...originalBatchItem,
            ...batchItem,
            pagePartition: newPagePartition
        });

        return this.upsert(chainStoreId, updatedBatchItem);
    }

    mapDealToBatchItem(batchItem: BatchItem, specials: Array<Special>): BatchItem {
        const bestDeal = this.specialsService.getBestDeal(specials);
        const salePrice = this.costService.getSalePrice(batchItem, specials);
        return new BatchItem({
            ...batchItem,
            salePrice: salePrice,
            deal: bestDeal
        });
    }

    updateBatchNegotiatedPrices(vendorDiscount: VendorDiscount): void {
        this.batch.forEach((batchItem) => {
            const isSameVendor = batchItem.vendorId === vendorDiscount.legacyVendorId;
            const hasNoSetDiscount = !batchItem.selectedVendorAttribute?.negotiatedPrice;
            const hasAvailableDiscount = !!vendorDiscount.discountPercentage;
            if (isSameVendor && hasNoSetDiscount && hasAvailableDiscount) {
                const negotiatedPrice = this.vendorDiscountService.calculateDiscountPrice(
                    batchItem.selectedVendorAttribute,
                    vendorDiscount
                );
                if (batchItem.selectedVendorAttribute) {
                    batchItem.selectedVendorAttribute.negotiatedPrice = negotiatedPrice;
                    this.updateItemInStorage(batchItem);
                    this.updateBatchSummary();
                }
            }
        });
    }

    updateBatchItemSaleInfo(chainStoreId: number, upc: string, specials: Array<Special>): Observable<BatchItemUpdate> {
        const batchItem = this.getBatchItem(upc);
        const specialsMatch = specials.filter((special) => {
            return (
                special.upc === batchItem.upc
                && special.vendorId === batchItem.vendorId
            );
        });
        const updatedBatchItem = new BatchItem({
            ...batchItem,
            ...this.mapDealToBatchItem(batchItem, specialsMatch)
        });
        const hasNewSalePrice = updatedBatchItem?.salePrice !== batchItem?.salePrice;
        const hasNewDeal = updatedBatchItem?.deal?.thresholdQty !== batchItem?.deal?.thresholdQty || batchItem?.deal === null;
        const shouldUpdateSalePrice = hasNewSalePrice || hasNewDeal;
        return shouldUpdateSalePrice ? this.upsert(chainStoreId, updatedBatchItem).pipe(
            tap(() => this.updateBatchSummary())
        ) : of(new BatchItemUpdate({ updatedBatchItem }));
    }

    updateBatchSaleInfo(specials: Array<Special>): Observable<Array<BatchItemUpdate>> {
        return this.userService.getCurrentUser().pipe(
            switchMap((user) => {
                return forkJoin({
                    batch: this.getBatch(user.currentStore.id),
                    chainStoreId: of(user.currentStore.id)
                });
            }),
            switchMap(({ batch, chainStoreId }) => {
                const requests = batch.map((batchItem) => {
                    const specialsMatch = specials.filter((special) => {
                        return (
                            special.upc === batchItem.upc
                            && special.vendorId === batchItem.vendorId
                        );
                    });
                    return this.updateBatchItemSaleInfo(chainStoreId, batchItem.upc, specialsMatch);
                });

                return forkJoin(requests).pipe(
                    tap(() => this.updateBatchSummary())
                );
            })
        );
    }

    mapProductToBatchItem(product: Product): BatchItem {
        return new BatchItem({
            brand: product?.brandName ?? '',
            description: product?.description ?? '',
            itemSize: product?.itemSize ?? ''
        });
    }

    mapVendorAttributeToBatchItem(vendorAttribute: VendorAttribute): BatchItem {
        return new BatchItem({
            casePackSize: vendorAttribute?.casePackSize,
            orderUnits: vendorAttribute?.orderUnits,
            sku: vendorAttribute?.sku,
            retailerSKU: vendorAttribute?.retailerSKU,
            srp: vendorAttribute?.srp,
            vendor: vendorAttribute?.vendor,
            vendorId: vendorAttribute?.vendor?.legacyId,
            wsp: vendorAttribute?.wsp,
            gtin: vendorAttribute?.gtin,
            ean: vendorAttribute?.ean,
            isbn: vendorAttribute?.isbn,
            productUpc: vendorAttribute?.upc,
            assortmentAttributes: vendorAttribute?.assortmentAttributes
        });
    }

    getBatchSummary(
        supportsApl = false
    ): BatchSummary {
        return new BatchSummary({
            itemCount: this.calculateItemCount(this.batch),
            totalCost: this.calculateTotalCost(
                this.batch,
                supportsApl
            ),
            type: BatchSummaryType.Product
        });
    }

    /**
     * @description
     * This is similar to @see getLastScannedOrFirstItem except that it does not return the first item from the batch when the last scanned
     * UPC is unset.
     * This is useful for cases where we need to simply know what the last scanned item was, but we don't need a fallback value.
     */
    getLastScannedItem(): BatchItem | null {
        return this.getBatchItem(this.lastScannedUpc) ?? null;
    }

    /**
     * @description
     * This is similar to @see getLastScannedItem except that it returns the first item from the batch when the last scanned UPC is unset.
     * In some cases, when there is no last scanned item, we can just take the first item. (e.g. on the product page, we want to display
     * the first product when the app loads)
     */
    getLastScannedOrFirstItem(): BatchItem {
        return this.getBatchItem(this.lastScannedUpc) ?? this.batch[0];
    }

    setLastScannedUpc(upc: string): void {
        this.lastScannedUpc = upc;
    }

    uploadBatch(
        chainStoreId: number,
        accountNumber: string,
        vendorId: number,
        directToVendor: boolean
    ): Observable<BatchUploadEvent<BatchItem>> {
        const delayInterval = 500; // half a second
        const retries = 3;
        return this.batchResource.update(chainStoreId, accountNumber, vendorId, directToVendor)
            .pipe(
                retryWithDelay(delayInterval, retries),
                switchMap(() => this.batchStorageService.clearStorage()),
                tap(() => this.productService.clear()),
                map(() => {
                    this.batch = !!vendorId
                        ? this.batch.filter((b) => b.vendorId !== vendorId)
                        : [];
                    this.pendingDeletions = !!vendorId
                        ? this.pendingDeletions.filter(
                            (b) => b.vendorId !== vendorId
                        )
                        : [];
                    this.updateBatchSummary();
                    this.setLastScannedUpc(null);
                    return new BatchUploadEvent({
                        items: this.batch
                    });
                }),
                catchError((error) => {
                    const offlineErrorStatus = 0;
                    const errorType =
                        error.status === offlineErrorStatus
                            ? UploadErrorType.Offline
                            : UploadErrorType.Other;
                    const batchUploadEvent = new BatchUploadEvent({
                        items: this.batch,
                        error: error.title,
                        lastError: Date.now(),
                        errorType
                    });
                    return of(batchUploadEvent);
                })
            );
    }

    /**
     * @description
     * Replaces existing batch items with the fully hydrated models. This is used after the batch is
     * returned from the API and product data has been added back in. This should only be called after the initial batch load.
     * @param batchItems The rehydrated batch items to replace existing items in the batch
     */
    updateRehydratedItemsAfterInitialLoad(batchItems: Array<BatchItem>): void {
        const updatedBatch = [...this.batch];

        batchItems.forEach((batchItem) => {
            let index = updatedBatch.findIndex(
                (item) => item.upc === batchItem.upc
            );
            index = index === -1 ? updatedBatch.length : index;
            updatedBatch[index] = batchItem;
        });

        this.batch = updatedBatch;
        this.hydrateStorage(this.batch);
    }

    removeProductsNotFound(batchItemUpdates: Array<BatchItemUpdate>): void {
        const notFoundItems = batchItemUpdates.filter((batchItem) =>
            batchItem.errors.includes(BatchItemUpdateError.ProductNotFound)
        );
        this.datadogRumService.addAction(
            'removeProductsNotFound: removing products not found from local batch',
            notFoundItems
        );
        notFoundItems.forEach((batchItemUpdate) => {
            this.removeItemFromStorage(batchItemUpdate.updatedBatchItem.upc);
        });

        const upcsToRemove = notFoundItems.map(
            (batchItem) => batchItem.updatedBatchItem.upc
        );
        this.batch = this.batch.filter((batchItem) => {
            return !upcsToRemove.includes(batchItem.upc);
        });
    }

    getAccountNumbers(
        vendorId: number,
        chainStoreId: number
    ): Observable<Array<OrderAccount>> {
        return this.accountsResource.getAll(vendorId, chainStoreId);
    }

    getPendingDeletions(): Array<BatchItem> {
        return this.pendingDeletions;
    }

    clear(): void {
        this.batch = [];
        this.pendingDeletions = [];
        this.hasLoadedBatch = false;
    }

    restoreBatchItem(
        chainStoreId: number,
        batchItem: BatchItem
    ): Observable<BatchItemUpdate> {
        // if the batch item is pending deletion then we had an offline error
        const pendingDeletion = this.getPendingDeletions().find(
            (item) => item.upc === batchItem.upc
        );
        if (pendingDeletion) {
            this.pendingDeletions = this.pendingDeletions.filter(
                (item) => item.upc !== batchItem.upc
            );
            return this.updateQuantity(
                chainStoreId,
                pendingDeletion,
                batchItem.quantity
            );
        }
        return this.updateQuantity(chainStoreId, batchItem, batchItem.quantity);
    }

    isValidItem = (batchItem: BatchItem): boolean => {
        const hasVendorId = batchItem.vendorId !== null && batchItem.vendorId !== undefined;
        const hasVendor = batchItem.vendor !== null && batchItem.vendor !== undefined;
        return hasVendorId && hasVendor;
    };

    /**
     * Removes an invalid item from being locally stored
     *
     * @param batchItems
     */
    removeInvalidBatchItems(batchItems: Array<BatchItem>): Observable<boolean> {
        const upcs = batchItems.map((batchItem) => batchItem.upc);
        const localRemovals = upcs.map(upc => this.batchStorageService.removeItem(upc));
        const remoteRemovals = batchItems.map(batchItem => {
            return this.batchResource.remove(batchItem.id).pipe(
                catchError(() => of(true))
            );
        });

        return forkJoin({
            localRemovals: forkJoin(localRemovals),
            remoteRemovals: forkJoin(remoteRemovals)
        })
            .pipe(
                tap(() => {
                    this.pendingDeletions = this.pendingDeletions.filter(item => !upcs.includes(item.upc));
                    this.batch = this.batch.filter((item) => !upcs.includes(item.upc));
                    this.updateBatchSummary();
                }),
                map(() => true));
    }

    private upsert(
        chainStoreId: number,
        batchItem: BatchItem
    ): Observable<BatchItemUpdate> {
        // If the upsert fails, the batch item will be marked pending. This is effectively an offline scan
        batchItem.hasPendingChanges = true;
        let request = this.batchResource.create(chainStoreId, batchItem);

        if (
            batchItem.selectedVendorAttribute !== null &&
            batchItem.selectedVendorAttribute !== undefined
        ) {
            batchItem = new BatchItem({
                ...batchItem,
                ...this.mapVendorAttributeToBatchItem(
                    batchItem.selectedVendorAttribute
                ),
                hasValidQuantity: batchItem.hasValidQuantity
            });
        }

        if (batchItem.quantity < 1) {
            request = this.batchResource.remove(batchItem.id).pipe(
                map(() => batchItem),
                tap(
                    () =>
                        (this.pendingDeletions = this.pendingDeletions.filter(
                            (item) => item.id !== batchItem.id
                        ))
                ),
                catchError((error) => {
                    this.pendingDeletions = [
                        ...this.pendingDeletions,
                        batchItem
                    ];
                    return throwError(error);
                })
            );
        } else if (batchItem.id) {
            request = this.batchResource.replace(batchItem.id, batchItem);
        }

        return request.pipe(
            map((apiBatchItem) => {
                const batchItemUpdate = new BatchItemUpdate({
                    updatedBatchItem: new BatchItem({
                        ...batchItem,
                        ...apiBatchItem,
                        hasPendingChanges: false
                    })
                });

                if (
                    batchItem.selectedVendorAttribute !== null &&
                    batchItem.selectedVendorAttribute !== undefined
                ) {
                    batchItemUpdate.updatedBatchItem = new BatchItem({
                        ...batchItemUpdate.updatedBatchItem,
                        // the apiBatchItem was overwriting certain vendor attributes
                        ...this.mapVendorAttributeToBatchItem(
                            batchItem.selectedVendorAttribute
                        ),
                        hasValidQuantity:
                        batchItemUpdate.updatedBatchItem.hasValidQuantity
                    });
                }

                return batchItemUpdate;
            }),
            catchError((error: ApiErrorResponse) => {
                const errors = [];
                if (error.status === ApiErrorCode.Unauthorized) {
                    errors.push(BatchItemUpdateError.NotAuthenticated);
                } else if (error.status === ApiErrorCode.Internal) {
                    errors.push(BatchItemUpdateError.InternalServerError);
                } else if (error.status === ApiErrorCode.Offline) {
                    errors.push(BatchItemUpdateError.UpsertOffline);
                    batchItem.hasPendingChanges = true;
                } else {
                    errors.push(BatchItemUpdateError.UndefinedError);
                }

                const result = new BatchItemUpdate({
                    updatedBatchItem: batchItem,
                    errors: errors
                });

                return of(result);
            }),
            map((batchItemUpdate) => {
                this.batch = this.updateItemInBatch(
                    this.batch,
                    batchItemUpdate.updatedBatchItem
                );
                return batchItemUpdate;
            }),
            tap((batchItemUpdate) => {
                const isProductNotFound = batchItemUpdate.errors.includes(
                    BatchItemUpdateError.ProductNotFound
                );
                const shouldUpdateItemInStorage =
                    batchItemUpdate.updatedBatchItem.quantity > 0 &&
                    !isProductNotFound;
                if (shouldUpdateItemInStorage) {
                    this.updateItemInStorage(batchItemUpdate.updatedBatchItem);
                } else {
                    this.removeItemFromStorage(
                        batchItemUpdate.updatedBatchItem.upc
                    );
                }
            })
        );
    }

    private updateItemInBatch(
        batch: Array<BatchItem>,
        batchItem: BatchItem
    ): Array<BatchItem> {
        let index = batch.findIndex((item) => item.upc === batchItem.upc);
        index = index === -1 ? batch.length : index;

        let updatedBatch = batch;
        if (batchItem.quantity < 1) {
            updatedBatch = batch.filter((item) => item.upc !== batchItem.upc);
        } else {
            updatedBatch = [
                ...batch.slice(0, index),
                batchItem,
                ...batch.slice(index + 1)
            ];
        }

        return updatedBatch;
    }

    getBatchSpecials() {
        return this.userService.getCurrentUser().pipe(
            switchMap((user) => {
                return this.batchSpecialsService.getBatchSpecialsQuery(
                    new SpecialsParameters({
                        daysBefore: 0,
                        daysAfter: 60,
                        chainStoreId: user.currentStore.id
                    }),
                    this.batch
                ).result$;
            }),
            tap(result => {
                const specials = result?.data ?? [];
                this.updateBatchSaleInfo(specials ?? [])
                    .pipe(take(1))
                    .subscribe();
            })
        );
    }

    getBatchItemSpecials(batchItem: BatchItem) {
        let chainStoreId = null;
        return this.userService.getCurrentUser().pipe(
            switchMap((user) => {
                chainStoreId = user.currentStore.id;
                return this.batchSpecialsService.getSpecialsQuery(new SpecialsParameters({
                    upcs: [batchItem?.upc],
                    vendorId: batchItem?.selectedVendorAttribute?.vendor?.legacyId ?? batchItem?.vendorId,
                    chainStoreId: user.currentStore.id
                })).result$;
            }),
            tap((result) => {
                const specials = result?.data ?? [];
                this.updateBatchItemSaleInfo(chainStoreId, batchItem.upc, specials)
                    .pipe(take(1))
                    .subscribe();
            })
        );
    }

    updateBatchSummary(): void {
        this.userService.getCurrentUser().pipe(
            map((user) => {
                return user.features.includes(
                    FeatureFlag.SupportsApl
                );
            }),
            takeUntil(this.unsubscribe))
            .subscribe((supportsApl) => {
                const batchSummary = this.getBatchSummary(
                    supportsApl
                );
                this.toolbarService.updateBatchSummary(batchSummary);
            });
    }

    private calculateItemCount(batch: Array<BatchItem>): number {
        return batch.reduce(
            (acc, batchItem) => acc + (batchItem.quantity ?? 0),
            0
        );
    }

    private calculateTotalCost(
        batch: Array<BatchItem>,
        supportsApl = false
    ): number {
        return batch.reduce((acc, batchItem) => {
            const itemCost =
                batchItem.quantity *
                this.costService.getPerUnitCost(
                    batchItem,
                    supportsApl
                );
            return acc + itemCost;
        }, 0);
    }

    private removeItemFromStorage(upc: string): void {
        this.batchStorageService
            .removeItem(upc)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }

    private updateItemInStorage(batchItem: BatchItem): void {
        this.batchStorageService
            .getItem(batchItem.upc)
            .pipe(
                switchMap((item) => {
                    const updatedBatchItem = batchItem;
                    if (item?.id) {
                        updatedBatchItem.id = item.id;
                    }
                    return this.batchStorageService.setItem(
                        batchItem.upc,
                        updatedBatchItem
                    );
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    /**
     * We don't want the initial hydration to be block the rendering of the page, so,
     * we hydrate out of the initial loading pipeline.
     *
     * @param batchItems
     */
    private hydrateStorage = (batchItems: Array<BatchItem>): void => {
        // ensure any leftover items are removed from the cache
        this.batchStorageService
            .clearStorage()
            .pipe(
                switchMap(() => {
                    return this.batchStorageService.setItems(batchItems);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    };
}

import { Injectable } from '@angular/core';
import { VendorResource } from '@buyiq-app/new-vendors/resources/vendor.resource';
import { Vendor } from '@buyiq-core/models/vendor';
import { Observable } from 'rxjs';
import { injectQuery } from '@ngneat/query';

@Injectable({
    providedIn: 'root'
})
export class NewVendorsService {
    private query = injectQuery();

    constructor(
        private vendorResource: VendorResource
    ) {
    }

    getVendor(legacyId: number): Observable<Vendor> {
        return this.vendorResource.get(legacyId, true);
    }

    getVendorQuery(legacyId: number) {
        return this.query({
            queryKey: ['vendor', legacyId],
            queryFn: () => this.getVendor(legacyId),
            refetchOnReconnect: false,
            staleTime: 60000
        });
    }
}

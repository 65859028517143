import { Injectable } from '@angular/core';
import { VendorAttribute } from '@buyiq-app/product/models/product';
import { VendorDiscount, VendorDiscountFilter } from '@buyiq-core/models/vendor-discount';
import { VendorDiscountsResource } from '@buyiq-core/resources/vendor-discounts.resource';
import { ApiCollectionResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';
import { injectQuery } from '@ngneat/query';

@Injectable({
    providedIn: 'root'
})
export class VendorDiscountService {
    private query = injectQuery();

    constructor(
        private vendorDiscountResource: VendorDiscountsResource
    ) {
    }

    getDiscounts(
        chainStoreId: number,
        searchState: SearchState,
        filter: VendorDiscountFilter
    ): Observable<ApiCollectionResponse<VendorDiscount>> {
        return this.vendorDiscountResource.getAll(chainStoreId, searchState, filter);
    }

    getDiscountsQuery(
        chainStoreId: number,
        searchState: SearchState,
        filter: VendorDiscountFilter
    ) {
        return this.query({
            queryKey: ['vendor-discounts', chainStoreId, filter.legacyVendorId, searchState],
            queryFn: () => this.getDiscounts(chainStoreId, searchState, filter),
            refetchOnReconnect: 'always',
            staleTime: 60000
        });
    }

    calculateDiscountPrice(
        { wsp }: VendorAttribute = new VendorAttribute(),
        { discountPercentage }: VendorDiscount
    ): number {
        const discountPrice = wsp - (wsp * discountPercentage) / 100;
        const roundedDiscount = Math.round(discountPrice * 10) / 10;
        return roundedDiscount;
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { OnlineManagerService } from '@buyiq-app/product/services/online-manager.service';
import { filter, forkJoin, Observable, of, Subject, tap } from 'rxjs';
import { SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { ProductParameters, ProductSearchParameters } from '@buyiq-app/product/models/product';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { User } from '@buyiq-core/models/user';
import { BatchService } from '@buyiq-app/batch/services/batch.service';
import { BatchItem, BatchItemUpdateError } from '@buyiq-app/batch/models/batch-item';
import { Notification, NotificationType } from '@buyiq-shared/models/notification';
import { ProductNotificationId } from '@buyiq-app/product/models/product-notification';
import { NotificationService } from '@buyiq-core/notifications/notification.service';
import { ScanService } from '@buyiq-core/scan/scan.service';
import { DatadogRumService } from '@buyiq-core/analytics/datadog-rum.service';
import { ProductService } from '@buyiq-app/product/services/product.service';
import { ApiErrorCode } from '@buyiq-core/models/error-handler';

@Injectable({
    providedIn: 'root'
})
export class InvalidBatchItemService implements OnDestroy {
    private upcsRemoved = [];
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private scanService: ScanService,
        private batchService: BatchService,
        private productService: ProductService,
        private notificationService: NotificationService,
        private onlineManagerService: OnlineManagerService,
        private dataDogRumService: DatadogRumService
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    removeInvalidBatchItem(batchItem: BatchItem): Observable<void> {
        this.dataDogRumService.addAction('checking if batch item is invalid', batchItem);
        const shouldRemoveItem = !this.batchService.isValidItem(batchItem) && this.onlineManagerService.isOnline;
        return this.isSearchOnline(1)
            .pipe(
                filter(isSearchOnline => isSearchOnline && shouldRemoveItem),
                switchMap(() => this.scanService.rehydrateBatchItem(batchItem)),
                filter(item => item.errors.includes(BatchItemUpdateError.ProductNotFound)),
                tap(() => {
                    this.dataDogRumService.addAction('removing invalid batch item', batchItem)
                }),
                switchMap(() => this.batchService.removeInvalidBatchItems([batchItem])),
                tap(() => {
                    this.displayInvalidItemDataNotification(batchItem)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe();
                }),
                map(() => null),
                catchError(() => of(null)),
            );
    }

    removeInvalidBatchItems(user: User): Observable<void> {
        // if we're offline, we can't guarantee we know if we have invalid batch items
        if (!this.onlineManagerService.isOnline) {
            return of(null);
        }

        return this.batchService.getBatch(user.currentStore.id)
            .pipe(
                tap(batch => {
                    this.dataDogRumService.addAction('Total batch items retrieved', { itemCount: batch.length });
                }),
                switchMap(batch => {
                    const hasInvalidItems = batch.some(item => !this.batchService.isValidItem(item));
                    return forkJoin({
                        batch: of(batch),
                        hasInvalidItems: of(hasInvalidItems),
                        // if we don't have invalid items then we don't need to check if search is online
                        isSearchOnline: hasInvalidItems
                            ? this.isSearchOnline(batch.length)
                            : of(true)
                    });
                }),
                tap(({ hasInvalidItems, isSearchOnline }) => {
                    // Log whether there are invalid items and if search is online
                    this.dataDogRumService.addAction('Checking invalid items and search status', {
                        hasInvalidItems: hasInvalidItems,
                        isSearchOnline: isSearchOnline
                    });
                }),
                switchMap(({ batch, isSearchOnline, hasInvalidItems }) => {
                    // if search is online, and we have invalid items then we need to remove them
                    const shouldRemoveInvalidItems = hasInvalidItems && isSearchOnline;
                    return shouldRemoveInvalidItems
                        ? this.handleInvalidBatchItems(batch)
                        : of(null);
                }),
                catchError(() => {
                    // Log if any error occurs
                    this.dataDogRumService.addAction('Error encountered in removeInvalidBatchItems');
                    return of(null);
                })
            );
    }

    /**
     * Checks if search is online
     *
     * @why We need to check if search is online because we could wrongly assume we have invalid batch items when
     * they just aren't hydrated.
     *
     * @param batchLength
     * @private
     */
    private isSearchOnline(batchLength = 1): Observable<boolean> {
        const searchState = new SearchState({
            pageSize: batchLength,
            sortBy: 'productname',
        });
        return this.productService.search(new ProductSearchParameters({
            searchState,
            productParameters: new ProductParameters(),
            temporaryVendorId: 0,
            batchSearch: true
        })).pipe(
            map(() => true),
            catchError(error => {
                if (error.status === ApiErrorCode.Offline) {
                    this.onlineManagerService.setOnline(false);
                }
                return of(false);
            })
        );
    }

    private handleInvalidBatchItems(batch: Array<BatchItem>): Observable<void> {
        const invalidItems = batch.filter(item => !this.batchService.isValidItem(item));
        if (invalidItems.length < 1) {
            return of(null);
        }

        // to be sure we have invalid items, we will attempt to rehydrate them
        // if they come back with BatchItemUpdateError.ProductNotFound then we know they are invalid
        return this.ensureItemsAreInvalid(batch)
            .pipe(
                switchMap(invalidBatchItems => {
                    if (invalidBatchItems.length > 0) {
                        return forkJoin({
                            invalidBatchItems: of(invalidBatchItems),
                            invalidUpdates: this.batchService.removeInvalidBatchItems(invalidBatchItems)
                        });
                    }

                    return forkJoin({
                        invalidBatchItems: of([]),
                        invalidUpdates: of(false)
                    });
                }),
                tap(({ invalidBatchItems }) => {
                    const notificationRequests = invalidBatchItems.map(item => {
                        return this.displayInvalidItemDataNotification(item);
                    });
                    forkJoin(notificationRequests)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe();
                }),
                map(() => null)
            );
    }

    private ensureItemsAreInvalid(batch: Array<BatchItem>): Observable<Array<BatchItem>> {
        const rehydrationRequests = batch.map(item => this.scanService.rehydrateBatchItem(item));

        return forkJoin(rehydrationRequests)
            .pipe(
                map(items => {
                    const invalidItems = items.filter(item => item.errors.includes(BatchItemUpdateError.ProductNotFound));
                    this.dataDogRumService.addAction('Invalid batch items found', invalidItems)
                    return invalidItems.map(item => item.updatedBatchItem);
                })
            );
    }

    private displayInvalidItemDataNotification(batchItem: BatchItem): Observable<boolean> {
        // prevents duplicate notifications
        if (this.upcsRemoved.includes(batchItem.upc)) {
            return of(true);
        }
        this.upcsRemoved = this.upcsRemoved.concat(batchItem.upc);

        const notification = new Notification({
            id: ProductNotificationId.NotFound,
            text: `Data for UPC ${batchItem.upc}  is no longer valid and is being removed from your batch`,
            type: NotificationType.Error
        });

        return this.notificationService.show(notification).pipe(
            tap(dismissed => this.upcsRemoved = this.upcsRemoved.filter(upc => upc !== batchItem.upc)),
        );
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BatchItem, BatchItemUpdate, BatchItemUpdateError } from '@buyiq-app/batch/models/batch-item';
import { BatchService } from '@buyiq-app/batch/services/batch.service';
import {
    Product,
    ProductSearchParameters,
    ProductSearchType,
    VendorAttribute
} from '@buyiq-app/product/models/product';
import { FeatureFlag } from '@buyiq-core/models/feature-flags';
import { ProductLookupResult, ProductScanConfiguration } from '@buyiq-core/models/scan';
import { Settings, User } from '@buyiq-core/models/user';
import { SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { VendorInfoService } from '@buyiq-app/product/services/vendor-info.service';
import { ProductService } from '@buyiq-app/product/services/product.service';
import { ApiErrorCode } from '@buyiq-core/models/error-handler';

@Injectable({
    providedIn: 'root'
})
export class ScanPipelineService {
    constructor(
        private productService: ProductService,
        private batchService: BatchService,
        private vendorInfoService: VendorInfoService
    ) {
    }

    getBatchItem(searchText: string, searchType: ProductSearchType = ProductSearchType.Upc): BatchItemUpdate {
        const isSku = this.productService.isSkuLookup(searchType, searchText);
        let searchBy: 'upc' | 'sku' | 'retailerSKU' = isSku
            ? searchType === ProductSearchType.RetailerSku ? 'retailerSKU' : 'sku'
            : 'upc';
        let originalBatchItem = this.batchService.getBatchItem(searchText, searchBy);

        const noRetailerSku = isSku && originalBatchItem === undefined && searchType === ProductSearchType.RetailerSku;
        if (noRetailerSku) {
            searchBy = 'sku';
            originalBatchItem = this.batchService.getBatchItem(searchText, searchBy);
        }

        // if we can't find the batch item with the given search type, try the other one
        if (originalBatchItem === undefined) {
            searchBy = isSku ? 'upc' : 'sku';
            originalBatchItem = this.batchService.getBatchItem(searchText, searchBy) ?? new BatchItem();
        }

        return new BatchItemUpdate({
            searchText: searchText,
            batchItemSnapshot: new BatchItem(originalBatchItem),
            product: originalBatchItem.product
        });
    }

    getBatchItemProductData(batchItemUpdate: BatchItemUpdate, config: ProductScanConfiguration): Observable<BatchItemUpdate> {
        let request = of(batchItemUpdate);

        if (!batchItemUpdate.product || !batchItemUpdate.product.vendorAttributes) {
            const searchState = new SearchState({
                search: config.searchText
            });

            request = this.productService.search(new ProductSearchParameters({
                searchState,
                productParameters: config.productParameters,
                temporaryVendorId: config.temporaryVendorId
            }))
                .pipe(
                    map(response => {
                        const products = response?.items || [];
                        const errors = [];

                        if (products.length === 0) {
                            errors.push(BatchItemUpdateError.ProductNotFound);
                        }

                        return new ProductLookupResult({
                            product: products[0],
                            errors: errors
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const errors = [];
                        let product: Product = null;

                        switch (error.status) {
                            case ApiErrorCode.Unauthorized:
                                errors.push(BatchItemUpdateError.NotAuthenticated);
                                break;
                            case ApiErrorCode.Offline:
                                errors.push(BatchItemUpdateError.ProductLookupOffline);
                                product = new Product({
                                    upc: config.searchText
                                });
                                break;
                            case ApiErrorCode.Internal:
                                errors.push(BatchItemUpdateError.InternalServerError);
                                product = new Product({
                                    upc: config.searchText
                                });
                                break;
                            default:
                                errors.push(BatchItemUpdateError.UndefinedError);
                                product = new Product({
                                    upc: config.searchText
                                });
                                break;
                        }

                        const result = new ProductLookupResult({
                            product: product,
                            errors: errors
                        });

                        return of(result);
                    }),
                    map(result =>
                        new BatchItemUpdate({
                            ...batchItemUpdate,
                            product: result.product,
                            errors: batchItemUpdate.errors.concat(result.errors)
                        })
                    )
                );
        }

        return request;
    }

    mapUpdatesToBatchItem(batchItemUpdate: BatchItemUpdate, config: ProductScanConfiguration): BatchItemUpdate {
        const product = batchItemUpdate.product;

        const selectedVendorAttribute = config.selectedVendorAttribute ??
            this.getSelectedVendorAttribute(
                product?.vendorAttributes,
                config.selectedLegacyVendorId,
                config.sku
            );

        const newQuantity = config.quantityOverride ??
            this.calculateNewQuantity(batchItemUpdate.batchItemSnapshot.quantity, config.numberOfTimesScanned, config.user.settings);

        const hasValidQuantity = this.productService.hasMetVendorOrderRequirements(newQuantity, selectedVendorAttribute);
        const department = this.getDepartment(config.user);

        const upc = product?.upc ?? config.searchText;
        const updatedBatchItem = new BatchItem({
            ...batchItemUpdate.batchItemSnapshot,
            ...this.batchService.mapProductToBatchItem(product),
            ...this.batchService.mapVendorAttributeToBatchItem(selectedVendorAttribute),
            product: product,
            upc,
            lnUpc: product?.lnUpc ? product.lnUpc : upc,
            quantity: newQuantity,
            hasValidQuantity: hasValidQuantity,
            selectedVendorAttribute: selectedVendorAttribute,
            pagePartition: config.pagePartition,
            department
        });

        if (config.wspOverride) {
            updatedBatchItem.wsp = config.wspOverride;
        }

        if (config.srpOverride) {
            updatedBatchItem.srp = config.srpOverride;
        }

        if (batchItemUpdate.errors.includes(BatchItemUpdateError.ProductNotFound)) {
            updatedBatchItem.quantity = 0;
        }

        return new BatchItemUpdate({
            ...batchItemUpdate,
            updatedBatchItem: updatedBatchItem
        });
    }

    uploadBatchItemChanges(batchItemUpdate: BatchItemUpdate, config: ProductScanConfiguration): Observable<BatchItemUpdate> {
        let request = of(batchItemUpdate);
        const hasProduct = !batchItemUpdate.errors.includes(BatchItemUpdateError.ProductNotFound);

        if (hasProduct) {
            request = this.vendorInfoService.isPartitionEnabled(batchItemUpdate.updatedBatchItem?.vendorId)
                .pipe(
                    mergeMap(partitionEnabled => {
                        if (!partitionEnabled) {
                            batchItemUpdate.updatedBatchItem.pagePartition = null;
                        }
                        return this.batchService.upsertBatchItem(config.user.currentStore.id, batchItemUpdate.updatedBatchItem)
                            .pipe(
                                map(upsertResult => {
                                    return new BatchItemUpdate({
                                        ...batchItemUpdate,
                                        updatedBatchItem: new BatchItem({
                                            ...batchItemUpdate.updatedBatchItem,
                                            ...upsertResult.updatedBatchItem
                                        }),
                                        errors: batchItemUpdate.errors.concat(upsertResult.errors)
                                    });
                                })
                            );
                    })
                );
        }
        return request;
    }

    getSelectedVendorAttribute(vendorAttributes: Array<VendorAttribute>, selectedLegacyVendorId?: number, selectedSku?: string): VendorAttribute {
        const attributes = vendorAttributes ?? [];
        let selectedVendorAttribute = attributes[0];

        if (selectedLegacyVendorId) {
            const hasMultipleOptions = attributes.filter(va => va.vendor?.legacyId === selectedLegacyVendorId).length > 1;
            if (hasMultipleOptions) {
                selectedVendorAttribute = attributes.find(va => va.vendor?.legacyId === selectedLegacyVendorId && va.sku === selectedSku) ?? selectedVendorAttribute;
            } else {
                selectedVendorAttribute = attributes.find(va => va.vendor?.legacyId === selectedLegacyVendorId) ?? selectedVendorAttribute;
            }
        }

        return selectedVendorAttribute;
    }

    calculateNewQuantity(currentQuantity: number, numberOfTimesScanned: number, settings: Settings): number {
        const quantity = currentQuantity || 0;
        const amountToIncrement = settings.scanIncrementsQuantity ? numberOfTimesScanned : 0;

        return quantity + amountToIncrement;
    }

    getDepartment(user: User): string {
        const isDepartmentBuyer = user.features.includes(FeatureFlag.DepartmentBuyer);
        const hasDepartment = user.department !== null && user.department !== undefined;
        return isDepartmentBuyer && hasDepartment ? user.department : '';
    }

    mapScannedOrderToBatchItem(batchItemUpdate: BatchItemUpdate): BatchItemUpdate {
        return new BatchItemUpdate({
            ...batchItemUpdate,
            updatedBatchItem: this.batchService.mapScannedOrderToBatchItem(batchItemUpdate.updatedBatchItem)
        });
    }
}

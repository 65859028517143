import { Injectable } from '@angular/core';
import { NewVendorsService } from '@buyiq-app/new-vendors/services/new-vendors.service';
import { VendorInfo } from '@buyiq-app/product/models/vendor-info';
import { VendorDiscountService } from '@buyiq-app/product/services/vendor-discount.service';
import { VendorInfoStorageService } from '@buyiq-app/product/services/vendor-info-storage.service';
import { VendorDiscountFilter } from '@buyiq-core/models/vendor-discount';
import { UserService } from '@buyiq-core/user/user.service';
import { SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { combineLatest, Observable, of, switchMap, take, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BatchService } from '@buyiq-app/batch/services/batch.service';
import { filterSuccessResult, takeUntilResultSuccess } from '@ngneat/query';

@Injectable({
    providedIn: 'root',
})
export class VendorInfoService {
    constructor(
        private userService: UserService,
        private batchService: BatchService,
        private newVendorsService: NewVendorsService,
        private vendorDiscountService: VendorDiscountService,
        private vendorInfoStorageService: VendorInfoStorageService
    ) {}

    getVendorInfo(legacyVendorId: number): Observable<VendorInfo> {
        if (!legacyVendorId) {
            return of(
                new VendorInfo({
                    partitionEnabled: false,
                    discounts: [],
                })
            );
        }
        const searchState = new SearchState({
            pageSize: 25,
        });
        const filters = new VendorDiscountFilter({
            legacyVendorId,
            startDate: new Date(),
        });

        return combineLatest({
            partitionEnabled: this.newVendorsService
                .getVendorQuery(legacyVendorId)
                .result$.pipe(
                    filterSuccessResult(),
                    takeUntilResultSuccess(),
                    map((result) => result?.data),
                    map(
                        (vendor) =>
                            vendor?.legacyVendor?.partitionEnabled ?? false
                    ),
                    catchError(() => of(false))
                ),
            discounts: this.userService.getCurrentUser().pipe(
                switchMap(
                    (user) =>
                        this.vendorDiscountService.getDiscountsQuery(
                            user.currentStore.id,
                            searchState,
                            filters
                        ).result$
                ),
                filterSuccessResult(),
                takeUntilResultSuccess(),
                map((discounts) => discounts?.data?.items ?? []),
                catchError(() => of([]))
            ),
        }).pipe(
            map(({ partitionEnabled, discounts }) => {
                const newVendorInfo = new VendorInfo({
                    legacyVendorId,
                    partitionEnabled,
                    discounts,
                });

                return newVendorInfo;
            }),
            tap((vendorInfo) => {
                if (vendorInfo.discounts.length > 0) {
                    this.batchService.updateBatchNegotiatedPrices(
                        vendorInfo.discounts[0]
                    );
                }
                this.vendorInfoStorageService
                    .setItem(`${legacyVendorId}`, vendorInfo)
                    .pipe(take(1))
                    .subscribe();
            }),
            catchError(() =>
                this.vendorInfoStorageService.getItem(`${legacyVendorId}`)
            )
        );
    }

    isPartitionEnabled(legacyVendorId: number): Observable<boolean> {
        return this.vendorInfoStorageService.getItem(`${legacyVendorId}`).pipe(
            switchMap((localVendorInfo) => {
                if (localVendorInfo) {
                    return of(localVendorInfo.partitionEnabled);
                }

                return this.getVendorInfo(legacyVendorId).pipe(
                    switchMap((vendorInfo) => of(vendorInfo.partitionEnabled)),
                    take(1)
                );
            })
        );
    }
}
